<template>
  <v-container>
    <!-- Poznámky pro soutěž -->
    <v-alert type="success" v-if="successMessage">{{ successMessage }}</v-alert>
    <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>
    <v-card v-if="isOrganizer">
      <v-toolbar flat dense>
        <v-tabs v-model="main" class="flex-grow-1" bg-color="primary" slider-color="#F1A829" center-active show-arrows>
          <!-- Zachování původní záložky eventu -->
          <v-tab value="event" @click="selectRace(race0.id)">{{ event.title }}</v-tab>
          <v-divider :thickness="10" class="border-opacity-100" vertical color="#FFFFFF"></v-divider>
          <!-- Dynamické generování záložek pro etapy získané z API a generace obsahu podle Race ID -->
          <v-tab v-for="(race, etap) in races" :key="race.id" :value="`race-${race.id}`" @click="selectRace(race.id)">
            E{{ etap + 1 }}: {{ race.title }}
          </v-tab>
          <!-- Tlačítko pro přidání další etapy se spuštěním dialogového okna -->
          <v-btn text @click="dialogAddRace = true" color="green" style="height:100%;"><v-icon size="28">mdi-plus-circle</v-icon></v-btn>
            <!-- Dialog pro přidání Race -->
            <v-dialog v-model="dialogAddRace" persistent max-width="400px">
            <v-card>
              <v-card-title class="text-h5">Přidat novou etapu</v-card-title>
              <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>
            <v-card-text>
              <v-form @submit.prevent="addRace">
                <v-text-field v-model="newRace.title" label="Název etapy" required :rules="eventTitle"></v-text-field>
                <v-text-field v-model="newRace.race_date" label="Datum" type="date" required :rules="eventDate"></v-text-field>
                <v-select :items="raceTypes" v-model="newRace.race_type" label="Typ etapy" :rules="eventType"></v-select>
                <v-card-actions>
                <v-btn color="blue darken-1" @click="dialogAddRace = false">Zrušit</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="addRace">Přidat etapu</v-btn>
              </v-card-actions>

              </v-form>
            </v-card-text>
            </v-card>
          </v-dialog>
        </v-tabs>
        <!-- Zachování tlačítka nastavení -->
        <v-btn text :to="'/soutez/' + event.id" style="height:100%;margin-inline-end: 0px"><v-icon size="28">mdi-arrow-left-circle</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-window v-model="main">
          <v-window-item value="event">
            <v-card v-if="event && event.club">
              <v-form ref="form" @submit.prevent="saveEvent">
              <v-row>
              <!-- První sloupec -->
                <v-col cols="12" md="6">
                  <v-card-title>{{ event.title }} <span v-if="event.event_date_start">{{ $formatDate(event.event_date_start) }}</span> <span v-if="event.event_date_end">- {{ $formatDate(event.event_date_end) }}</span></v-card-title>
                  <v-card-text>
                    <v-col>Soutěž ID: {{ event.id }}</v-col>
                    <v-text-field label="Název soutěže" v-model="event.title" :rules="eventTitle" maxlength="60" counter></v-text-field>
                    <v-text-field v-model="event.event_date_start" label="Datum začátku" type="date" :rules="eventDate"></v-text-field>
                    <v-text-field label="Datum konce" v-model="event.event_date_end" type="date"></v-text-field>
                    <v-text-field label="Start 00" v-if="races.length === 0 & isRace0" v-model="race0.start00Time" type="time" :value="race0.start00 ? $formatTime(race0.start00) : ''" @input="updateStart00Time(race0)" clearable></v-text-field>

                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-title color="primary">Další nastavení soutěže</v-expansion-panel-title>
                        <v-expansion-panel-text>
                          <v-select label="Typ soutěže" v-model="event.event_type" :items="eventTypes" :rules="eventType"></v-select>
                          <v-select label="Viditelnost" v-model="event.visibility" :items="visibilityTypes" :rules="visibilityType"></v-select>
                          <v-switch v-model="event.canceled" label="Nastavit zrušení soutěže" color="primary"></v-switch>
                          <v-text-field label="Souřadnice shromaždiště" v-model="event.gps"></v-text-field>
                          <v-text-field label="Kontakt na organizátora" v-model="event.contact"></v-text-field>
                          <v-text-field label="Zpráva - informace" v-model="race0.message_info" maxlength="500" counter></v-text-field>
                          <v-text-field label="Zpráva - varování" v-model="race0.message_warning" maxlength="500" counter></v-text-field>
                          <v-textarea label="Poznámka" v-model="event.event_note" maxlength="1000" counter></v-textarea>
                        </v-expansion-panel-text>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card-text>

                    <!-- Dialog pro potvrzení smazání Eventu -->
                    <v-dialog v-model="dialogDeleteEvent" persistent max-width="600px">
                      <v-card>
                        <v-card-title class="text-h5">Potvrzení smazání</v-card-title>
                        <v-card-text>Chcete opravdu smazat tuto soutěž <b>{{event.title}}</b>? Tato akce je nevratná a smaže i všechny etapy a data!!!</v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="dialogDeleteEvent = false">Zrušit</v-btn>
                          <v-btn color="red darken-1" text @click="deleteEvent">Smazat</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                </v-col>

                  <!-- Druhý sloupec -->
                  <v-col cols="12" md="3">
                    <v-card-title>Nastavení přihlašování</v-card-title>
                    <v-switch v-model="event.entry_is_open" label="Povolit přihlašování na soutěž" color="primary"></v-switch>
                    <v-text-field v-model="race0.entry_deadline_1" type="datetime-local" label="1. termín přihlášek"></v-text-field>
                    <v-text-field v-model="race0.entry_deadline_2" type="datetime-local" label="2. termín přihlášek"></v-text-field>
                    <v-switch v-model="event.allow_start_choice" color="primary" label="Umožnit závodníkům vybrat si dobu startu"></v-switch>

                    <a
                      class="custom-btn"
                      @click="dialogCategory = true"
                      title="změnami v této tabulce přepíšete kategorie ve všech etapách">
                      Spravovat kategorie hromadně
                    </a>

                    <!-- Dialogové okno pro správu kategorií -->
                    <v-dialog v-model="dialogCategory" max-width="1000px">
                      <v-card>
                        <v-card-title>
                          Správa kategorií
                          <v-btn color="green darken-1" @click="addNewCategory" style="float:right">Přidat kategorii</v-btn>
                        </v-card-title>
                        <v-card-text>
                        <v-row>
                          <v-col cols="12" md="4">
                            <v-text-field v-model="bulkFee" label="Hromadné startovné" @change="applyBulkFee" suffix=",-Kč"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field v-model="bulkIncreasedFee" label="Hromadné zvýšené startovné" @change="applyBulkIncreasedFee" suffix=",-Kč"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field v-model="bulkFeeEvent" label="Hromadné startovné soutěže" @change="applyBulkFeeEvent" suffix=",-Kč"></v-text-field>
                          </v-col>
                        </v-row>

                        <v-data-table
                            :items="categories"
                            :items-per-page="-1"
                            :headers="categoryHeaders"
                            item-key="id"
                            class="elevation-1"
                            :sort-by="[{ key: 'option'}]">

                            <template v-slot:item="{ item }">

                              <tr>

                                <td>
                                  <v-select v-model="item.option" :items="CategoryType" dense flat solo style="min-width: 160px" :rules="optionRules"></v-select>
                                </td>
                                <td><v-text-field v-model="item.name" dense flat solo :rules="nameRules"></v-text-field></td>
                                <!-- <td>
                                  <v-select
                                    v-model="item.sex"
                                    :items="[ { title: 'Žena', value: 'F' }, { title: 'Muž', value: 'M' }]"
                                    dense flat solo
                                    style="min-width: 100px;"
                                    :rules="sexRules"
                                    ></v-select>
                                </td> -->
                                <td><v-text-field v-model="item.fee" type="number" dense flat solo suffix=",-Kč"></v-text-field></td>
                                <td><v-text-field v-model="item.increased_fee" type="number" dense flat solo suffix=",-Kč"></v-text-field></td>
                                <td><v-text-field v-model="item.fee_event" type="number" dense flat solo suffix=",-Kč"></v-text-field></td>
                                <td style="min-width: 50px;"><v-icon color="red" small @click="markCategoryForDeletion(item.id)">mdi-delete</v-icon></td>

                              </tr>
                            </template>
                          </v-data-table>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn color="grey" @click="dialogCategory = false">Zavřít</v-btn>
                          <v-spacer></v-spacer>
                          <v-switch
                            v-model="change_allcategories"
                            color="primary"
                            label="Přepsat i v etapách"
                            hide-details
                          ></v-switch>
                          <v-btn color="green darken-1" @click="dialogCategoryRace0show" :disabled="!NotNameOption()">Uložit</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <!-- Dialog pro potvrzení uložení a přepsání všech kategorií -->
                    <v-dialog v-model="dialogCategoryRace0" persistent max-width="600px" class="dialog-over-dialog">
                      <v-card>
                        <v-card-title class="text-h5">Potvrzení uložení</v-card-title>
                        <v-card-text>Uložením přepíšete všechny kategorie ve všech etapách soutěže <b>{{ event.title }}</b>. Jednotlivé katgorie etap můžeš měnit v samostatných etapách. Opravdu chceš přepsat všechny kategorie?</v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="dialogCategoryRace0 = false">Zrušit</v-btn>
                          <v-btn color="green darken-1" text @click="updateCategory">Aktualizovat a přepsat</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                  </v-col>

                <!-- Třetí sloupec -->
                <v-col cols="12" md="3">
                  <v-card>
                  <v-card-title>
                    Soubory:
                    <v-btn color="surface" @click="showAddFileSection = !showAddFileSection" style="float: right;"><v-icon color="green" size="35" @click="addNewDay">mdi-plus</v-icon></v-btn>
                  </v-card-title>
                  <v-card-text>

                    <!-- Formulář pro přidání nového souboru -->
                    <div v-if="showAddFileSection">
                      <v-card>
                        <v-card-text>
                          <v-text-field v-model="newFile.name" label="Název souboru" placeholder="Např. Propozice" :rules="fileName"></v-text-field>
                          <v-select label="Typ souboru" v-model="newFile.specific" :items="fileTypes"></v-select>
                          <v-file-input label="Vybrat soubor" name="location" @change="handleNewFileChange" :error-messages="fileError"></v-file-input>
                          <v-btn color="success" @click="addNewFile">Nahrát</v-btn>
                        </v-card-text>
                      </v-card>
                    </div>

                      <v-list  v-for="file in filesWithLocation" :key="file.id">

                        <div v-if="!file.editing" class="d-flex justify-space-between">
                          <v-list-item-title>
                            <a :href="`${BaseUrl}${file.location}`" target="_blank" class="font-weight-bold">{{ file.name }}</a>
                          </v-list-item-title>

                        <v-list-item-action>
                          <!-- Ikona pro editaci -->
                          <v-icon color="blue" size="x-large" @click="editFile(file)" class="mr-2">mdi-pencil</v-icon>
                          <!-- Delete File Button -->
                          <v-icon color="red" size="x-large" @click="file.dialogDeleteFile = true">mdi-delete</v-icon>
                        </v-list-item-action>

                          <!-- Dialog pro potvrzení smazání Souboru -->
                          <v-dialog v-model="file.dialogDeleteFile" persistent max-width="600px">
                            <v-card>
                              <v-card-title class="text-h5">Potvrzení smazání souboru</v-card-title>
                              <v-card-text>Chcete opravdu smazat tento soubor <b>{{file.name}} - {{file.location}}</b>? Tato akce je nevratná!</v-card-text>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="file.dialogDeleteFile = false">Zrušit</v-btn>
                                <v-btn color="red darken-1" text @click="deleteFile(file.id, file)">Smazat soubor</v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>

                        </div>

                        <div v-else style="background-color: #F4F6F6">
                          <!-- Editace souboru -->
                          <v-text-field v-model="file.name" label="Popis odkazu na soubor" placeholder="např. Propozice" :rules="fileName"></v-text-field>
                          <!-- Výběr kategorie -->
                          <v-select label="Typ soutěže (volitelně)" v-model="file.specific" :items="fileTypes" density="compact"></v-select>
                          <!-- File Upload Input -->
                          <v-file-input label="Vložit soubor" @change="handleNewFileChange($event, file)" outlined dense density="compact" :error-messages="fileError"></v-file-input>
                          <div>{{ file.location }}</div>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                          <!-- Uložení změn -->
                          <v-icon color="blue" @click="updateFiles(file)" size="34">mdi-content-save</v-icon>
                          </v-card-actions>
                        </div>
                      </v-list>
                  </v-card-text>
                  </v-card>

                  <v-card class="mt-2">
                    <v-card-title>
                      Odkazy:
                      <v-btn color="surface" @click="showAddLinkSection = !showAddLinkSection" style="float: right;" class="ml-3"><v-icon color="green" size="35" @click="addNewDay">mdi-plus</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                      <!-- Formulář pro přidání nového odkazu -->
                      <div v-if="showAddLinkSection">
                        <v-card>
                          <v-card-text>
                            <v-text-field v-model="newLink.name" label="Název odkazu" placeholder="Např. Počasí" :rules="linkName"></v-text-field>
                            <v-text-field v-model="newLink.url" label="URL odkazu" placeholder="https://"></v-text-field>

                            <v-btn color="success" @click="addNewLink">Přidat odkaz</v-btn>
                          </v-card-text>
                        </v-card>
                      </div>
                      <v-list v-for="file in filesWithLinks" :key="file.id">
                        <div v-if="!file.editing" class="d-flex justify-space-between">
                          <v-list-item-title><a :href="file.url" target="_blank" class="font-weight-bold">{{ file.name }}</a></v-list-item-title>
                          <v-list-item-action>
                            <v-icon color="blue" size="x-large" @click="editLink(file)" class="mr-2">mdi-pencil</v-icon>
                            <v-icon color="red" size="x-large" @click="file.dialogDeleteFile = true" >mdi-delete</v-icon>
                          </v-list-item-action>
                          <!-- Dialog pro potvrzení smazání Odkazu -->
                          <v-dialog v-model="file.dialogDeleteFile" persistent max-width="600px">
                              <v-card>
                                <v-card-title class="text-h5">Potvrzení smazání souboru</v-card-title>
                                <v-card-text>Chcete opravdu smazat tento odkaz <b>{{file.name}} - {{file.url}}</b>? Tato akce je nevratná!</v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="file.dialogDeleteFile = false">Zrušit</v-btn>
                                  <v-btn color="red darken-1" text @click="deleteFile(file.id, file)">Smazat odkaz</v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                        </div>
                        <div v-else style="background-color: #F4F6F6">
                            <!-- Editace souboru -->
                            <v-text-field v-model="file.name" label="Název odkazu" placeholder="např. Počasí" :rules="linkName"></v-text-field>
                            <v-text-field v-model="file.url" label="URL odkazu" placeholder="https://"></v-text-field>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                            <!-- Uložení změn -->
                            <v-icon color="blue" @click="updateFiles(file)" size="34">mdi-content-save</v-icon>
                            </v-card-actions>
                          </div>

                      </v-list>
                    </v-card-text>
                  </v-card>
                <!-- Konec třetího sloupce -->
                </v-col>

              </v-row>

              <v-row class="d-flex justify-end">
                <v-col cols="12" md="auto" class="d-flex flex-column flex-md-row align-items-md-end">
                  <v-btn color="success" type="submit" class="mb-2 w-100 w-md-auto order-md-1">Uložit změny</v-btn>
                  <v-btn color="error" dark @click="dialogDeleteEvent = true" class="w-100 w-md-auto mr-5">Smazat soutěž</v-btn>
                </v-col>
              </v-row>

            </v-form>

            </v-card>
          </v-window-item>


          <!-- Dynamické generování obsahu pro etapy -->
          <v-window-item v-for="(race, etap) in races" :key="race.id" :value="`race-${race.id}`">
          <v-form ref="form" @submit.prevent="saveEvent">
          <v-row>
            <v-col cols="12" md="6">
              <v-card-title>Etapa {{ etap + 1 }}: {{ race.title }}</v-card-title>
              <v-card-text>
                <v-col>ID: {{ race.id }}</v-col>
                <v-text-field v-model="race.title" label="Název etapy" :rules="eventTitle" maxlength="60" counter></v-text-field>
                <v-text-field v-model="race.race_date" label="Datum etapy" type="date" required :rules="eventDate"></v-text-field>
                <v-text-field v-model="race.start00Time" type="time" label="Start 00" :value="race.start00 ? $formatTime(race.start00) : ''" @input="updateStart00Time(race)" clearable></v-text-field>

                <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-title color="primary">Další nastavení etapy</v-expansion-panel-title>
                        <v-expansion-panel-text>
                          <v-select :items="raceTypes" v-model="race.race_type" label="Typ etapy" :rules="eventType"></v-select>
                          <v-select v-model="race.discipline" :items="disciplines" label="Disciplína"></v-select>
                          <v-text-field v-model="race.limit" type="time" label="Limit v hh:mm"></v-text-field>
                          <v-text-field v-model="race.message_info" label="Zpráva - informace" maxlength="500" counter></v-text-field>
                          <v-text-field v-model="race.message_warning" label="Zpráva - varování" maxlength="500" counter></v-text-field>
                          <v-textarea v-model="race.race_note" label="Poznámka" maxlength="1000" counter></v-textarea>
                        </v-expansion-panel-text>
                      </v-expansion-panel>
                    </v-expansion-panels>
              </v-card-text>

              <!-- Dialog pro potvrzení smazání Etapy -->
              <v-dialog v-model="race.dialogDeleteRace" persistent max-width="600px">
                <v-card>
                  <v-card-title class="text-h5">Potvrzení smazání</v-card-title>
                  <v-card-text>Chcete opravdu smazat tuto Etapu <b>{{race.title}}</b>? Tato akce je nevratná a smaže i všechna data v etapě!!!</v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="race.dialogDeleteRace = false">Zrušit</v-btn>
                    <v-btn color="red darken-1" text @click="deleteRace(event.id, race.id, race), race.dialogDeleteRace = false">Smazat</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>


            </v-col>


                <!-- Druhý sloupec -->
                <v-col cols="12" md="3">
                    <!-- <v-card-title>Termín přihlášky pro Etapu</v-card-title>
                    <v-text-field v-model="race.entry_deadline_1" type="datetime-local" label="1. termín přihlášek pro etapu"></v-text-field>
                    <v-text-field v-model="race.entry_deadline_2" type="datetime-local" label="2. termín přihlášek pro etapu"></v-text-field> -->


                    <div cols="12" md="12" class="text-center">
                    <v-btn color="primary" @click="dialogCategoryRace = true" class="w-100">Spravovat kategorie</v-btn>
                    </div>
                    <!-- Dialogové okno pro správu kategorií -->
                    <v-dialog v-model="dialogCategoryRace" max-width="800px">
                      <v-card>
                        <v-card-title>
                          Správa kategorií
                          <v-btn color="green darken-1" @click="addNewCategory" style="float: right;">Přidat kategorii</v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-row>
                          <v-col cols="6">
                            <v-text-field v-model="bulkFee" label="Hromadné startovné" @change="applyBulkFee" suffix=",-Kč"></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field v-model="bulkIncreasedFee" label="Hromadné zvýšené startovné" @change="applyBulkIncreasedFee" suffix=",-Kč"></v-text-field>
                          </v-col>
                        </v-row>
                          <v-data-table
                            :items="categories"
                            :items-per-page="-1"
                            :headers="categoryHeadersEvents"
                            item-key="id"
                            class="elevation-1"
                            :sort-by="[{ key: 'option'}]">

                            <template v-slot:item="{ item }">

                              <tr>

                                <td>
                                  <v-select v-model="item.option" :items="CategoryType" dense flat solo style="min-width: 160px;" :rules="optionRules"></v-select>
                                </td>
                                <td><v-text-field v-model="item.name" dense flat solo :rules="nameRules"></v-text-field></td>

                                <td><v-text-field v-model="item.fee" type="number" dense flat solo suffix=",-Kč"></v-text-field></td>
                                <td><v-text-field v-model="item.increased_fee" type="number" dense flat solo suffix=",-Kč"></v-text-field></td>
                                <td style="min-width: 50px;"><v-icon color="red" small @click="markCategoryForDeletion(item.id)">mdi-delete</v-icon></td>

                              </tr>

                            </template>
                          </v-data-table>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn color="grey" @click="dialogCategoryRace = false">Zavřít</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn color="green darken-1" @click="updateCategory">Uložit</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                </v-col>

                <!-- Třetí sloupec -->
                <v-col cols="12" md="3">
                  <v-card>
                  <v-card-title>
                    Soubory:
                    <v-btn color="surface" @click="showAddFileSection = !showAddFileSection" style="float: right;"><v-icon color="green" size="35" @click="addNewDay">mdi-plus</v-icon></v-btn>
                  </v-card-title>
                  <v-card-text>

                    <!-- Formulář pro přidání nového souboru -->
                    <div v-if="showAddFileSection">
                      <v-card>
                        <v-card-text>
                          <v-text-field v-model="newFile.name" label="Název souboru" placeholder="Např. Propozice" :rules="fileName"></v-text-field>
                          <v-select label="Typ souboru" v-model="newFile.specific" :items="fileTypes"></v-select>
                          <v-file-input label="Vybrat soubor" name="location" @change="handleNewFileChange" :error-messages="fileError"></v-file-input>
                          <v-btn color="success" @click="addNewFile">Nahrát</v-btn>
                        </v-card-text>
                      </v-card>
                    </div>

                      <v-list  v-for="file in filesWithLocation" :key="file.id">

                        <div v-if="!file.editing" class="d-flex justify-space-between">
                          <v-list-item-title>
                            <a :href="`${BaseUrl}${file.location}`" target="_blank" class="font-weight-bold">{{ file.name }}</a>
                          </v-list-item-title>

                        <v-list-item-action>
                          <!-- Ikona pro editaci -->
                          <v-icon color="blue" size="x-large" @click="editFile(file)" class="mr-2">mdi-pencil</v-icon>
                          <!-- Delete File Button -->
                          <v-icon color="red" size="x-large" @click="file.dialogDeleteFile = true">mdi-delete</v-icon>
                        </v-list-item-action>

                          <!-- Dialog pro potvrzení smazání Souboru -->
                          <v-dialog v-model="file.dialogDeleteFile" persistent max-width="600px">
                            <v-card>
                              <v-card-title class="text-h5">Potvrzení smazání souboru</v-card-title>
                              <v-card-text>Chcete opravdu smazat tento soubor <b>{{file.name}} - {{file.location}}</b>? Tato akce je nevratná!</v-card-text>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="file.dialogDeleteFile = false">Zrušit</v-btn>
                                <v-btn color="red darken-1" text @click="deleteFile(file.id, file)">Smazat soubor</v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>

                        </div>

                        <div v-else style="background-color: #F4F6F6">
                          <!-- Editace souboru -->
                          <v-text-field v-model="file.name" label="Popis odkazu na soubor" placeholder="např. Propozice" :rules="fileName"></v-text-field>
                          <!-- Výběr kategorie -->
                          <v-select label="Typ soutěže (volitelně)" v-model="file.specific" :items="fileTypes" density="compact"></v-select>
                          <!-- File Upload Input -->
                          <v-file-input label="Vložit soubor" @change="handleNewFileChange($event, file)" outlined dense density="compact" :error-messages="fileError"></v-file-input>
                          <div style="margin-top: -18px;">{{ file.location }}</div>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                          <!-- Uložení změn -->
                          <v-icon color="blue" @click="updateFiles(file)" size="34">mdi-content-save</v-icon>
                          </v-card-actions>
                        </div>
                      </v-list>
                  </v-card-text>
                  </v-card>

                  <v-card class="mt-2">
                    <v-card-title>
                      Odkazy:
                      <v-btn color="surface" @click="showAddLinkSection = !showAddLinkSection" style="float: right;" class="ml-3"><v-icon color="green" size="35" @click="addNewDay">mdi-plus</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                      <!-- Formulář pro přidání nového odkazu -->
                      <div v-if="showAddLinkSection">
                        <v-card>
                          <v-card-text>
                            <v-text-field v-model="newLink.name" label="Název odkazu" placeholder="Např. Počasí" :rules="linkName"></v-text-field>
                            <v-text-field v-model="newLink.url" label="URL odkazu" placeholder="https://"></v-text-field>

                            <v-btn color="success" @click="addNewLink">Přidat odkaz</v-btn>
                          </v-card-text>
                        </v-card>
                      </div>
                      <v-list v-for="file in filesWithLinks" :key="file.id">
                        <div v-if="!file.editing" class="d-flex justify-space-between">
                          <v-list-item-title><a :href="file.url" target="_blank" class="font-weight-bold">{{ file.name }}</a></v-list-item-title>
                          <v-list-item-action>
                            <v-icon color="blue" size="x-large" @click="editLink(file)" class="mr-2">mdi-pencil</v-icon>
                            <v-icon color="red" size="x-large" @click="file.dialogDeleteFile = true" >mdi-delete</v-icon>
                          </v-list-item-action>
                          <!-- Dialog pro potvrzení smazání Odkazu -->
                          <v-dialog v-model="file.dialogDeleteFile" persistent max-width="600px">
                              <v-card>
                                <v-card-title class="text-h5">Potvrzení smazání souboru</v-card-title>
                                <v-card-text>Chcete opravdu smazat tento odkaz <b>{{file.name}} - {{file.url}}</b>? Tato akce je nevratná!</v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="file.dialogDeleteFile = false">Zrušit</v-btn>
                                  <v-btn color="red darken-1" text @click="deleteFile(file.id, file)">Smazat odkaz</v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                        </div>
                        <div v-else style="background-color: #F4F6F6">
                            <!-- Editace souboru -->
                            <v-text-field v-model="file.name" label="Název odkazu" placeholder="např. Počasí" :rules="linkName"></v-text-field>
                            <v-text-field v-model="file.url" label="URL odkazu" placeholder="https://"></v-text-field>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                            <!-- Uložení změn -->
                            <v-icon color="blue" @click="updateFiles(file)" size="34">mdi-content-save</v-icon>
                            </v-card-actions>
                          </div>

                      </v-list>
                    </v-card-text>
                  </v-card>
            <!-- Konec třetího sloupce -->
                </v-col>
              </v-row>

              <v-row class="d-flex justify-end">
                <v-col cols="12" md="auto" class="d-flex flex-column flex-md-row align-items-md-end">
                  <v-btn color="success" type="submit" class="mb-2 w-100 w-md-auto order-md-1">Uložit změny</v-btn>
                  <v-btn color="error" dark @click="race.dialogDeleteRace = true" class="w-100 w-md-auto mr-5">Smazat Etapu</v-btn>
                </v-col>
              </v-row>

              </v-form>

          </v-window-item>

        </v-window>
      </v-card-text>

      <!-- Další záložky, které jsou pro celý event -->

      <v-tabs v-model="second" bg-color="primary" fixed-tabs slider-color="#F1A829">
        <v-tab value="entries" v-if="isRace0 && entries.length > 0 || searchSurname">Správa přihlášených</v-tab>
        <v-tab value="services" v-if="isRace0">Ubytování a strava</v-tab>
        <v-tab value="startlist" v-if="races.length === 0 || !isRace0">Startovka</v-tab>
        <!--
        <v-tab value="online_results">Online výsledky</v-tab>
        <v-tab value="results">Výsledky</v-tab> -->


      </v-tabs>
      <v-card-text>


        <!-- ***************************************
 *******************************************
 ********  Správa přihlášených    **********
 *******************************************
 ****************************************-->



        <v-window v-model="second">

          <v-window-item value="entries" v-if="isRace0 && entries.length > 0 || searchSurname">


            <v-row class="align-center justify-space-between">
              <v-col cols="12" md="4">
                <v-card-title>Editace všech přihlášených</v-card-title>
              </v-col>

              <v-col cols="12" md="2">


                <v-menu offset-y transition="slide-y-transition">
                    <template v-slot:activator="{ props }">
                    <v-btn color="green" text v-bind="props">
                        Exporty <v-icon right>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item :href="`${BaseUrl}/export/?type=xlsx&event_id=${event.id}`"><v-list-item-title>Soutěž xlsx</v-list-item-title></v-list-item>
                      <v-list-item :href="`${BaseUrl}/export/?type=csv&name=entry_matula&event_id=${event.id}`"><v-list-item-title>Přihlášky Matula csv</v-list-item-title></v-list-item>
                      <v-list-item :href="`${BaseUrl}/export/?type=csv&name=entry_ocm&event_id=${event.id}`"><v-list-item-title>Přihlášky OCM csv</v-list-item-title></v-list-item>
                    </v-list>
                  </v-menu>

              </v-col>
              <v-col cols="12" md="3">
                <v-btn color="primary" @click="saveEmailsToClipboard">Zkopírovat emaily</v-btn>
                <v-snackbar v-model="showSnackbar" top color="success">
                  Emaily byly zkopírovány.
                </v-snackbar>
              </v-col>
              <v-col cols="12" md="3" class="text-right">
                <v-text-field
                  v-model="searchSurname"
                  label="Vyhledat podle příjmení"
                  @input="fetchEntries"
                  @click:clear="resetSearch"
                  solo
                  clearable
                  hide-details
                  style="max-width: 300px; margin-left: auto;"
                ></v-text-field>
              </v-col>
            </v-row>


            <v-card-text>

              <v-data-table
                :headers="entryHeaders"
                :items="entries"
                item-key="id"
                class="elevation-1"
                density="comfortable"
                :items-per-page="100"
                :sort-by="sortByEntries"
                @touchstart.stop
                @touchmove.stop
                :loading="isLoadingEntries"
                loading-text="Načítám data..."
              >
                <template v-slot:[`item.surname`]="{ item }">
                  {{ item.surname }}
                </template>
                <template v-slot:[`item.first_name`]="{ item }">
                  {{ item.first_name }}
                </template>
                <template v-slot:[`item.index`]="{ item }">
                  {{ item.index }}
                </template>
                <template v-slot:[`item.si_number`]="{ item }">
                  {{ item.si_number }}
                </template>
                <template v-slot:[`item.category`]="{ item }">
                  {{ item.category_name }}
                </template>
                <template v-slot:[`item.termin_prihl`]="{ item }">
                  {{ item.termin_prihl }}
                </template>
                <template v-slot:[`item.note`]="{ item }">
                  {{ item.note }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">

                    <v-list-item-action>
                    <v-icon @click="openEditDialog(item)" color="blue" size="x-large" class="mr-2">mdi-pencil</v-icon>
                    <v-icon @click="openDeleteDialog(item)" color="red" size="x-large">mdi-delete</v-icon>
                  </v-list-item-action>
                </template>
              </v-data-table>
            </v-card-text>



            <!-- Dialog pro editaci Entry -->
            <v-dialog v-model="dialogEditEntry" persistent max-width="1200px">
              <v-card>
                <v-card-text>
                  <h3 class="my-2">
                    {{ selectedEntry?.surname }} {{ selectedEntry?.first_name }} ({{ selectedEntry?.index }})
                  </h3>
                  <div class="mb-3" v-for="(race, index) in allRaces" :key="race.id" :class="{ 'first-row': index === 0 }">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="getEntryRaceData(selectedEntry, race.id).category_id"
                          :items="race.categories"
                          item-title="name"
                          item-value="id"
                          :label="index === 0 ? `Kategorie pro hlavní soutěž ${race.title}` : `Kategorie pro etapu ${race.title}`"
                          :clearable="index !== 0"
                          hide-details="true"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="getEntryRaceData(selectedEntry, race.id).si_number"
                          :label="index === 0 ? `Číslo SI pro hlavní soutěž ${race.title}` : `Číslo SI pro etapu ${race.title}`"
                          type="number"
                          hide-details="true"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>

                  <!-- Sekce pro výběr ubytování podle dnů -->
                  <h3 class="py-2 my-1" style="background-color: #e8efff">Ubytování:</h3>

                  <div v-for="variant in accommodationVariants" :key="variant.variant">
                    <span :style="{ color: variant.limit && variant.count >= variant.limit ? 'red' : 'inherit' }">
                      Typ {{ variant.variant }}: {{ variant.count }} přihlášených
                      <span v-if="variant.limit">/ limit: {{ variant.limit }}</span>
                    </span>
                  </div>




                  <v-row>
                    <!-- Pro každé datum zobrazíme vlastní sloupec -->
                    <v-col
                      v-for="(date, index) in Object.keys(availableAccommodations)"
                      :key="index"
                      cols="12"
                      md="3"
                    >
                      <h4>{{ $formatDate(date) }}</h4>
                      <div v-if="availableAccommodations[date].length === 1">
                        <v-checkbox
                          v-model="selectedEntry.accommodation[date]"
                          :label="availableAccommodations[date][0].name"
                          :value="availableAccommodations[date][0].id"
                        ></v-checkbox>
                      </div>
                      <div v-else>
                        <v-select
                          v-model="selectedEntry.accommodation[date]"
                          :items="availableAccommodations[date]"
                          item-title="name"
                          item-value="id"
                          label="Vyberte ubytování"
                          clearable
                        ></v-select>
                      </div>
                    </v-col>
                  </v-row>



                  <!-- Sekce pro výběr stravy podle dnů -->
                  <h3 class="py-2 my-1" style="background-color: #e8efff">Strava:</h3>
                  <v-row>
                    <v-col
                      v-for="(date, index) in Object.keys(availableFood)"
                      :key="index"
                      cols="12"
                      md="3"
                    >
                      <h4>{{ $formatDate(date) }}</h4>

                      <!-- Snídaně -->
                      <v-subheader v-if="availableFood[date].breakfasts.length">Snídaně</v-subheader>
                      <v-checkbox
                        v-for="(item, idx) in availableFood[date].breakfasts"
                        :key="`breakfast-${idx}`"
                        v-model="selectedEntry.food[date].breakfasts"
                        :label="item.name"
                        :value="item.id"
                        hide-details
                      ></v-checkbox>

                      <!-- Obědy -->
                      <template v-if="availableFood[date].lunches.length">
                        <v-subheader>Oběd</v-subheader>
                        <div v-if="availableFood[date].lunches.length === 1">
                          <v-checkbox
                            v-for="(item, idx) in availableFood[date].lunches"
                            :key="`lunch-${idx}`"
                            v-model="selectedEntry.food[date].lunches"
                            :label="item.name"
                            :value="item.id"
                            hide-details
                          ></v-checkbox>
                        </div>
                        <div v-else>
                          <v-select
                            v-model="selectedEntry.food[date].lunches"
                            :items="availableFood[date].lunches"
                            item-title="name"
                            item-value="id"
                            label="Vyberte oběd"
                            clearable
                            hide-details
                          ></v-select>
                        </div>
                      </template>

                      <!-- Večeře -->
                      <template v-if="availableFood[date].dinners.length">
                        <v-subheader>Večeře</v-subheader>
                        <div v-if="availableFood[date].dinners.length === 1">
                          <v-checkbox
                            v-for="(item, idx) in availableFood[date].dinners"
                            :key="`dinner-${idx}`"
                            v-model="selectedEntry.food[date].dinners"
                            :label="item.name"
                            :value="item.id"
                            hide-details
                          ></v-checkbox>
                        </div>
                        <div v-else>
                          <v-select
                            v-model="selectedEntry.food[date].dinners"
                            :items="availableFood[date].dinners"
                            item-title="name"
                            item-value="id"
                            label="Vyberte večeři"
                            clearable
                            hide-details
                          ></v-select>
                        </div>
                      </template>
                    </v-col>
                  </v-row>

                  <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="grey" text @click="dialogEditEntry = false">Zrušit</v-btn>
                  <v-btn color="green darken-1" text @click="editEntry">Uložit</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>



            <!-- Dialog pro potvrzení smazání Entry -->
            <v-dialog v-model="dialogDeleteEntry" persistent max-width="600px">
              <v-card>
                <v-card-title class="text-h5">Potvrzení smazání přihlášky</v-card-title>
                <v-card-text>Chcete opravdu smazat přihlášku pro <b>{{ selectedEntry?.surname }} {{ selectedEntry?.first_name }}</b>? Tato akce je nevratná a smaže přihlášky závodníka ve všech etapách!!!</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialogDeleteEntry = false">Zrušit</v-btn>
                  <v-btn color="red darken-1" text @click="deleteEntry">Smazat</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>








          </v-window-item>







          <v-window-item value="services" v-if="isRace0">

            <v-form ref="entryForm">
              <v-row >
                <div v-for="(acc, index) in accommodations" :key="index">
                  <v-col>
                    <v-row justify="space-between">
                      <v-col cols="auto">
                        <span>Ubytování - varianta {{ acc.variant }}</span>
                      </v-col>
                      <v-col cols="auto">
                        <v-icon v-if="acc.name" color="red" title="resetovat formulář a vymazat jeho data" size="x-large" @click="showAccomodationDialog(index)">mdi-close</v-icon>
                      </v-col>
                    </v-row>
                    <v-text-field v-model="acc.name" label="Název ubytování" maxlength="30" counter :error-messages="fieldErrors[`acc${index}`]"></v-text-field>
                    <v-text-field v-model="acc.price" label="Cena" type="number" :error-messages="fieldErrors[`acc${index}`]"></v-text-field>
                    <v-text-field v-model="acc.limit" label="Kapacita" type="number"></v-text-field>
                    <v-text-field v-model="acc.date_from" :disabled="acc.loadedFromAPI" label="Datum od" type="date" :error-messages="fieldErrors[`acc${index}`]"></v-text-field>
                    <v-text-field v-model="acc.date_to" :disabled="acc.loadedFromAPI" label="Datum do" type="date" :error-messages="fieldErrors[`acc${index}`]"></v-text-field>
                    <!-- <v-switch v-model="acc.only_all_days" color="primary" label="Přihlášení pouze na všechny dny"></v-switch> -->
                  </v-col>

                  <!-- Dialog pro potvrzení smazání ubytování -->
                  <v-dialog v-model="dialogAccomodations[index]" persistent max-width="600px">
                    <v-card>
                      <v-card-title class="text-h5">Potvrzení smazání ubytování</v-card-title>
                      <v-card-text>Chceš opravdu smazat tento typ ubytování s názvem <b>{{ acc.name }}</b>? Tato akce je nevratná!</v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialogAccomodations[index] = false">Zrušit</v-btn>
                        <v-btn color="red darken-1" text @click="resetAccommodation(index); dialogAccomodations[index] = false;">Smazat variantu</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                </div>
              </v-row>

              <!-- Jídla -->
              <v-col cols="auto">
                <v-btn color="primary" @click="addNewDay"><v-icon color="green" size="35" @click="addNewDay">mdi-plus</v-icon> Přidej další dny jídla</v-btn>
              </v-col>
              <v-row>
                <v-col cols="12" md="4" v-for="(day, index) in days" :key="index" :class="{'background-color-1': index % 2 === 0, 'background-color-2': index % 2 !== 0}">
                  <v-text-field v-model="day.date" :error-messages="fieldErrors['date' + day.date]" label="Datum" clearable type="date"></v-text-field>
                  <v-row>
                    <!-- Header for Breakfast -->
                    <v-col cols="12" md="4">
                      <div class="text-center">Snídaně</div>
                      <div v-for="meal in day.breakfasts" :key="meal.variant">
                        <v-text-field v-model="meal.name" label="Název" :error-messages="fieldErrors['sn' + meal.variant]" clearable maxlength="30" counter></v-text-field>
                        <v-text-field v-model="meal.price" label="Cena" type="number" :error-messages="fieldErrors['sn' + meal.variant]" clearable></v-text-field>
                      </div>
                    </v-col>
                    <!-- Header for Lunch -->
                    <v-col cols="12" md="4">
                      <div class="text-center">Oběd</div>
                        <div v-for="meal in day.lunches" :key="meal.variant">
                        <v-text-field v-model="meal.name" :label="`Název (varianta ${meal.variant})`" :error-messages="fieldErrors['ob' + meal.variant]" clearable maxlength="30" counter></v-text-field>
                        <v-text-field v-model="meal.price" label="Cena" type="number" :error-messages="fieldErrors['ob' + meal.variant]" clearable></v-text-field>
                      </div>
                    </v-col>
                    <!-- Header for Dinner -->
                    <v-col cols="12" md="4">
                      <div class="text-center">Večeře</div>
                      <div v-for="meal in day.dinners" :key="meal.variant">
                        <v-text-field v-model="meal.name" :label="`Název (varianta ${meal.variant})`" :error-messages="fieldErrors['ve' + meal.variant]" clearable maxlength="30" counter></v-text-field>
                        <v-text-field v-model="meal.price" label="Cena" type="number" :error-messages="fieldErrors['ve' + meal.variant]" clearable></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <div style="display: flex; justify-content: flex-end; margin-top: 20px;">
                <v-btn color="success" @click="submitEntryService">Uložit stravu a ubytování</v-btn>
              </div>

            </v-form>

          </v-window-item>







<!-- ***************************************
 *******************************************
 ********       STARTOVKA         **********
 *******************************************
 ****************************************-->




          <v-window-item v-if="races.length === 0 || !isRace0" value="startlist">
            <v-alert type="success" v-if="successMessage">{{ successMessage }}</v-alert>

            <v-row class="justify-space-between">
                <v-col cols="12" md="3">
                  <v-btn @click="openStartlistStepperDialog" color="success">Nahrát startovku</v-btn>
                </v-col>

                <v-col cols="12" md="2" v-if="startlist.length > 0">
                <v-menu offset-y transition="slide-y-transition">
                  <template v-slot:activator="{ props }">
                  <v-btn color="green" text v-bind="props">
                      Exporty {{  currentRaceId.value }}<v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item :href="`${BaseUrl}/export/?type=pdf&name=startlist_starter&race_id=${currentRaceId}`"><v-list-item-title>Startovka startér</v-list-item-title></v-list-item>
                    <v-list-item :href="`${BaseUrl}/export/?type=csv&name=startlist&race_id=${currentRaceId}`"><v-list-item-title>Startovka csv</v-list-item-title></v-list-item>
                    <v-list-item :href="`${BaseUrl}/export/?type=txt&name=si_droid_race&race_id=${currentRaceId}`"><v-list-item-title>SI Droid txt</v-list-item-title></v-list-item>
                  </v-list>
                </v-menu>
                </v-col>

                <v-col cols="12" md="3" v-if="startlist.length > 0">
                  <v-btn color="error" @click="openDeleteAllStartTimesDialog">Smazat startovku</v-btn>
                </v-col>
              </v-row>


    <!-- Dialog pro potvrzení smazání všech start_time -->
<v-dialog v-model="dialogDeleteAllStartTimes" persistent max-width="400px">
  <v-card>
    <v-card-title class="text-h5">Potvrzení smazání</v-card-title>
    <v-card-text>
      Opravdu chcete smazat všechny startovní časy pro tento závod?
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="dialogDeleteAllStartTimes = false">Zrušit</v-btn>
      <v-btn color="red darken-1" text @click="deleteAllStartTimes">Smazat</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>


    <v-data-table  v-if="startlist.length > 0" :items="startlist" :headers="GetStartlistHeaders" item-key="index" :items-per-page="100" :sort-by="StartlistSortBy" @touchstart.stop @touchmove.stop>
      <template v-slot:[`item.first_name`]="{ item }">
        {{ item.first_name }}
      </template>

      <template v-slot:[`item.surname`]="{ item }">
        {{ item.surname }}
      </template>

      <template v-slot:[`item.start_time`]="{ item }">
        {{ $formatTimeSec(item.start_time) || 'N/A' }}
      </template>

      <template v-slot:[`item.category_name`]="{ item }">
        {{ item.category_name }}
      </template>

      <template v-slot:[`item.index`]="{ item }">
        {{ item.index }}
      </template>

      <template v-slot:[`item.si_number`]="{ item }">
        {{ item.si_number }}
      </template>

      <template v-slot:[`item.club_name`]="{ item }">
        {{ item.club_name }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">

        <v-list-item-action>
        <!--<v-icon @click="openEditStartlistDialog(item)" color="blue" size="x-large" class="mr-2">mdi-pencil</v-icon>-->
        <v-icon @click="openDeleteGetStartDialog(item)" color="red" size="x-large" title="smazat start">mdi-delete</v-icon>
      </v-list-item-action>
    </template>

    </v-data-table>

    <!-- Dialog pro potvrzení smazání Start -->
<v-dialog v-model="dialogDeleteStart" persistent max-width="400px">
  <v-card>
    <v-card-title class="text-h5">Potvrzení smazání</v-card-title>
    <v-card-text>
      Chcete opravdu smazat start pro <b>{{ selectedStart.surname }} {{ selectedStart.first_name }}</b>?
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="dialogDeleteStart = false">Zrušit</v-btn>
      <v-btn color="red darken-1" text @click="deleteGetStart">Smazat</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>


      <v-dialog v-model="dialogAddStartlist" max-width="1200px">
      <v-card>
        <v-row no-gutters>
      <v-spacer></v-spacer>
      <v-icon
        color="gray"
        title="zavřít"
        size="large"
        @click="closeStartlistDialog"
        >
        mdi-close
      </v-icon>
    </v-row>
        <v-card-text>
          <v-stepper hide-actions v-model="stepStart" :items="['Nahraj startovku', 'Kontrola dat']">
            <template v-slot:[`item.1`]>
              <v-card>
                <v-card-text>
                  Nahrej csv soubor v požadovaném formátu dat, kódování UTF-8.<br>
                  Startovní číslo;Příjmení;Jméno;kategorie;relativní čas startu;absolutní čas startu;index;volačka;stát;číslo čipu.<br>
                  Např. 3;Novák;Petr;M20;65:30;11:05:30;GBM8105;OK123;CZE;2045258
                </v-card-text>
                <v-file-input
                  label="Vložit soubor"
                  outlined
                  dense
                  density="compact"
                  @change="handleFileUpload"
                  :error-messages="errorStartlistMessage"
                ></v-file-input>

                <v-btn
                  color="primary"
                  @click="uploadStartlistFile"
                  :disabled="!isFileValid"
                  class="mt-4"
                >
                  Nahrát
                </v-btn>

                <span v-if="successStartlistMessage" style="color:green; font-size:11px;" class="ma-2">
                  {{ successStartlistMessage }}
                </span>

              </v-card>
            </template>



            <template v-slot:[`item.2`]>

<v-row class="mb-1">
  <v-col cols="2" style="background:green">Vše OK</v-col>
  <v-col cols="5" style="background:orange">V přihláškách ROBisu nejsou</v-col>
  <v-col cols="5" style="background:red">Mají přihlášku, nemají start</v-col>

</v-row>
              <v-data-table
  :headers="startlistHeaders"
  :items="combinedStarts"
  item-key="index"
  class="elevation-1"
  density="comfortable"
  :items-per-page="100"
  :row-props="setRowProps"
>

<template v-slot:[`item.bib_number`]="{ item }">
    {{ item.bib_number }}
  </template>

  <template v-slot:[`item.surname`]="{ item }">
    {{ item.surname }}
  </template>

  <template v-slot:[`item.first_name`]="{ item }">
    {{ item.first_name }}
  </template>

  <template v-slot:[`item.index`]="{ item }">
    {{ item.index }}
  </template>

  <template v-slot:[`item.category_name`]="{ item }">
    {{ item.category_name }}
  </template>


  <template v-slot:[`item.si_number`]="{ item }">
    {{ item.si_number }}
  </template>


  <template v-slot:[`item.start_time_relative`]="{ item }">
    {{ item.start_time_relative }}
  </template>

  <template v-slot:[`item.start_time_real`]="{ item }">
    {{ item.start_time_real }}
  </template>


  <template v-slot:[`item.actions`]="{ item }">
    <v-list-item-action>
      <v-icon @click="openEditStartDialog(item)" color="blue" size="x-large" class="mr-2">mdi-pencil</v-icon>
      <v-icon @click="openDeleteStartDialog(item)" color="red" size="x-large">mdi-delete</v-icon>
    </v-list-item-action>
  </template>
  <template v-slot:bottom></template>
</v-data-table>

<!-- Zobrazení chybového hlášení -->
<div v-if="errorStartlistSendMessage.length" style="color:red; font-size:11px;" class="ma-2">
    <div v-for="(error, index) in errorStartlistSendMessage" :key="index">
      {{ error }}
    </div>
  </div>


  <!-- Dialog pro editaci Start -->
  <v-dialog v-model="dialogEditStart" persistent max-width="600px">
    <v-card>
      <v-card-title>
        {{ selectedStart.surname }} {{ selectedStart.first_name }}
      </v-card-title>
      <v-card-text>
        <!-- <v-row>    Příjmení a jméno by se nemělo měnit kvůli přiřazoání
        <v-col cols="12" md="6">
          <v-text-field
            v-model="selectedStart.surname"
            label="Příjmení"
            :rules="[v => !!v || 'Příjmení je povinné']"
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="selectedStart.first_name"
            label="Jméno"
            :rules="[v => !!v || 'Jméno je povinné']"
            dense
          ></v-text-field>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col cols="12" md="6">
        <v-text-field v-model="selectedStart.index" hide-details label="Index" dense disabled></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
        <v-text-field v-model="selectedStart.category_name" hide-details label="Kategorie" dense></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
        <v-text-field v-model="selectedStart.bib_number" hide-details type="number" label="Startovní číslo" dense></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field v-model="selectedStart.si_number" hide-details type="number" label="Číslo čipu" dense></v-text-field>
      </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" md="6">
        <v-text-field v-model="selectedStart.start_time_relative" :value="selectedStart.start_time_relative" hide-details type="time" step="2" label="Start relativní" dense></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field v-model="selectedStart.start_time_real" :value="selectedStart.start_time_real" hide-details type="time" step="2" label="Start reálný" dense></v-text-field>
      </v-col>
    </v-row>

      </v-card-text>
      <v-card-actions>
        <v-btn color="grey" text @click="dialogEditStart = false">Zrušit</v-btn>
        <v-btn color="green darken-1" text @click="saveEditStart" :disabled="!selectedStart.first_name || !selectedStart.surname">Uložit</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

  <!-- Dialog pro potvrzení smazání Start -->
  <v-dialog v-model="dialogDeleteStart" persistent max-width="400px">
    <v-card>
      <v-card-title class="text-h5">Potvrzení smazání</v-card-title>
      <v-card-text>
        Chcete opravdu smazat start pro <b>{{ selectedStart.surname }} {{ selectedStart.first_name }}</b>?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialogDeleteStart = false">Zrušit</v-btn>
        <v-btn color="red darken-1" text @click="deleteStart">Smazat</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


</template>


<v-stepper-actions>
  <template v-slot:next>
    <v-btn color="primary" v-if="stepStart < totalStartSteps" @click="stepStart++">Další</v-btn>
    <v-btn color="green darken-1" v-else @click="saveStartData" :disabled="false">Uložit</v-btn>
  </template>
  <template v-slot:prev>
    <v-btn variant="plain" v-if="stepStart > 1" @click="stepStart--">Zpět</v-btn>
    <v-btn variant="plain" v-else>&nbsp;</v-btn>
  </template>
</v-stepper-actions>




          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>


          </v-window-item>



<!--
          <v-window-item value="results">
            Výsledky zde
          </v-window-item>
        -->
        </v-window>
      </v-card-text>


    </v-card>

  </v-container>
</template>






<script setup>
import { ref, computed, onMounted, watch, reactive } from 'vue';
import apiClient from '/api.js';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '/auth.js';
import { format } from 'date-fns';


const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();


const event = ref({
  entry_is_open: false,
  entry_deadline_1: ""
});
const eventTypes = ref([
  { title: 'Závody', value: 'races' },
  { title: 'Soustředění', value: 'camp' },
  { title: 'Ostatní', value: 'other' },

  // { title: 'Reprezentační', value: 'repre' },
  // { title: 'SCM', value: 'scm' },
  // { title: 'Asociační', value: 'association' }
]);

const visibilityTypes = ref([
  { title: 'Veřejná', value: 'public' },
  { title: 'Pouze oddíl', value: 'club' },
  ]);

const races = ref([]); // Seznam etap (Race) získaných z API kromě prvního Race
const race0 = ref({});
const currentRaceId = ref(null); // Zde uchováváme aktuálně vybrané ID závodu
const files = ref([]);
const showAddFileSection = ref(false); // Řídí zobrazení sekce pro přidání nového souboru
const showAddLinkSection = ref(false); // Zobrazit pole pro nový odkaz
const newFile = ref({ name: '', specific: null, location: null }); // Data pro nový soubor
const newLink = ref({ name: '', url: '' }); // data pro nový odkaz
const fileTypes = ref([
  { title: 'Propozice', value: 'bulletin' },
  { title: 'Pokyny', value: 'info' },
  { title: 'Startovka', value: 'startlist' },
  { title: 'Výsledky', value: 'results' }
]);
const newRace = ref({ title: '', race_date: '', race_type: '' });
const raceTypes = ref([
  { title: 'Trénink', value: 'training' },
  { title: 'Soutěž III. stupně', value: '3st' },
  { title: 'Soutěž II. stupně', value: '2st' },
  { title: 'MČR/NŽ - I. stupeň', value: 'mcr-nz' },
  // { title: 'Štafety', value: 'relay' },
  { title: 'Ostatní', value: 'other' },
  // { title: 'Reprezentační akce', value: 'repre' },
  // { title: 'ŽDR akce', value: 'scm' },
  // { title: 'Asociační', value: 'association' }
]);
const disciplines = ref([
  { title: '3,5 MHz klasika', value: '80' },
  { title: '144 MHz klasika', value: '2' },
  { title: 'Sprint', value: 'sprint' },
  { title: 'Foxoring', value: 'foxoring' },
  { title: '3,5 MHz KT', value: '80kt' },
  { title: '144 MHz KT', value: '2kt' },
  { title: 'Dlouhá trať 80m+2m', value: 'mix' },
  { title: 'Noční závod', value: 'night' },
  // { title: 'Štafety', value: 'relay' }
]);


const categories = ref([]); // Seznam kategorií pro zvolený Race nebo pro Race0 v rámci Eventu
const categoriesToDelete = ref([]);

const categoryHeaders = ref([ // Hlavičky tabulky pro kategorie
  { title: 'Kategorie', key: 'option' },
  { title: 'Název', key: 'name' },
  { title: 'Startovné', key: 'fee' },
  { title: 'Zvýšené startovné', key: 'increased_fee' },
  { title: 'Startovné soutěže', key: 'fee_event' },
  { text: 'Akce', value: 'actions', sortable: false }
]);

const categoryHeadersEvents = ref([ // Hlavičky tabulky pro kategorie
  { title: 'Kategorie', key: 'option' },
  { title: 'Název', key: 'name' },
  { title: 'Startovné', key: 'fee' },
  { title: 'Zvýšené startovné', key: 'increased_fee' },
  { text: 'Akce', value: 'actions', sortable: false }
]);


const main = ref('event'); // Přidáno pro inicializaci na první záložku main části
const second = ref('entries'); // Přidáno pro inicializaci na první záložku second části
const BaseUrl = ref(process.env.VUE_APP_API_BASE_URL.replace(/\/+$/, '').replace(/\/api\/?$/, '')); // Odstraní "api/" a nadbytečná lomítka
const successMessage = ref('');
const errorMessage = ref('');
const dialogDeleteEvent = ref(false);
const dialogDeleteRace = ref(false);
const dialogAddRace = ref(false);
const dialogCategory = ref(false);
const dialogCategoryRace = ref(false);
const change_allcategories = ref(false);
const dialogCategoryRace0 = ref(false);
const dialogDeleteFile = ref(false);

const CategoryType = ref([
        { title: 'D7 (0-7)', value: 'W7' },
        { title: 'D9 (0-9)', value: 'W9' },
        { title: 'D12 (0-12)', value: 'W12' },
        { title: 'D14 (0-14)', value: 'W14' },
        { title: 'D16 (0-16)', value: 'W16' },
        { title: 'D19 (0-19)', value: 'W19' },
        { title: 'D20 (0-99)', value: 'W21' },
        { title: 'D35 (35-99)', value: 'W35' },
        { title: 'D45 (45-99)', value: 'W45' },
        { title: 'D55 (55-99)', value: 'W55' },
        { title: 'D65 (65-99)', value: 'W65' },
        { title: 'M7 (0-7)', value: 'M7' },
        { title: 'M9 (0-9)', value: 'M9' },
        { title: 'M12 (0-12)', value: 'M12' },
        { title: 'M14 (0-14)', value: 'M14' },
        { title: 'M16 (0-16)', value: 'M16' },
        { title: 'M19 (0-19)', value: 'M19' },
        { title: 'M20 (0-99)', value: 'M21' },
        { title: 'M40 (40-99)', value: 'M40' },
        { title: 'M50 (50-99)', value: 'M50' },
        { title: 'M60 (60-99)', value: 'M60' },
        { title: 'M70 (70-99)', value: 'M70' },
        { title: 'MDR (0-13)', value: 'MDR' },
        { title: 'Ostatní (0-99)', value: 'others' },
]);

 // error hlášky
const eventTitle = ref([v => !!v || 'Název je povinný']);
const eventDate = ref([v => !!v || 'Datum je povinné']);
const eventType = ref([v => !!v || 'Typ je povinný']);
const visibilityType = ref([v => !!v || 'Typ je povinný']);


const nameRules = ref([v => !!v || 'Název je povinný']);
// const maxAgeRules = ref([
//   v => !!v || 'Max věk je povinný',
//   v => (v && v > 0) || 'Věk musí být větší než 0'
// ]);
// const sexRules = ref([v => !!v || 'Pohlaví je povinné']);
const optionRules = ref([v => !!v || 'Musíš vybrat typ']);

// const fileNameRules = ref([v => !!v || 'Název souboru je povinný']);
// const linkNameRules = ref([v => !!v || 'Název odkazu je povinný']);


// const formatDateTimeForInput = (datetime) => {
//   return datetime ? datetime.replace('Z', '').substring(0, 16) : '';
// };

// const parseDateTimeFromInput = (datetime) => {
//   return datetime ? datetime + ':00' : '';
// };


// Oprávnění organizátora, závisí na tom, jestli je organizátor ve stejném klubu jako event
const isOrganizer = computed(() => {
  // Převod Proxy objektu na běžné pole pro jednoduchý přístup
  const rolesArray = Array.from(authStore.roles);
  const isRoleOrganizer = rolesArray.includes('organizer');
  const isSameClub = Number(authStore.userClub) === Number(event.value.club);
  return isRoleOrganizer && isSameClub;
});


// Sledujte změnu v aktuálně vybrané záložce a mění Race ID
watch(main, (newValue) => {
  if (newValue === 'event' && race0.value.id) {
    currentRaceId.value = race0.value.id;
  }
});

const isRace0 = computed(() => currentRaceId.value === race0.value.id); // zjištění, jestli se jedná o hlavní stránku, potom zobraz přihlášené a Ryceservices v template

// Filtrování souborů pro zobrazení pouze odkazů
const filesWithLinks = computed(() => files.value.filter(file => file.is_link));
// Filtrování souborů pro zobrazení pouze souborů
const filesWithLocation = computed(() => files.value.filter(file => file.is_file));

onMounted(async () => {
  await loadEventAndRacesData(); // Načtení dat eventu a etap
  checkForLocalMessages(); // zkontroluje, jestli není potřeba načíst success message po úspěšném uložení soutěže
  fetchRaceServiceData();
  fetchStartlist();
});


const checkForLocalMessages = () => {
  const message = localStorage.getItem('successMessage');
  if (message) {
    successMessage.value = message;
    localStorage.removeItem('successMessage'); // Odstranění zprávy po jejím zobrazení
  }
};


const loadEventAndRacesData = async () => {
  const eventId = route.params.id; // event ID z URL adresy
  try {
    const response = await apiClient.get(`event/edit/?id=${eventId}`);
    event.value = response.data;

    // Seřadit závody podle ID
    const sortedRaces = response.data.races.sort((a, b) => a.id - b.id);
    // uloží hodoty pro první race0
    race0.value = sortedRaces.length > 0 ? sortedRaces[0] : null;
    // Odstranit první závod (s nejnižším ID)
    const racesWithoutFirst = sortedRaces.slice(1);
    // Uložit upravené závody do races v pořadí podle ryce_date a start00
    races.value = racesWithoutFirst.sort((a, b) => {
      if (a.race_date < b.race_date) return -1;
      if (a.race_date > b.race_date) return 1;
      if (a.start00 < b.start00) return -1;
      return a.start00 > b.start00 ? 1 : 0;
    });


    // allRaces data pro editaci přihlášených
    allRaces.value = response.data.races.map((race, index) => {
      if (index !== 0) {
        race.categories.push({
          id: null,
          option: 'nepřihlášen',
          name: 'nepřihlášen',
          race: race.id
        });
      }
      race.categories.sort((a, b) => {
        if (a.name === 'nepřihlášen') return -1;
        if (b.name === 'nepřihlášen') return 1;
        return a.name.localeCompare(b.name);
      });
      return race;
    });

    // Seřadit allRaces podle ID
    allRaces.value = allRaces.value.sort((a, b) => a.id - b.id);
    // Uložit první závod a ostatní závody do allRaces v pořadí podle race_date a start00
    allRaces.value = [
      allRaces.value[0],
      ...allRaces.value.slice(1).sort((a, b) => {
        const dateComparison = new Date(a.race_date) - new Date(b.race_date);
        if (dateComparison !== 0) return dateComparison;
        if (!a.start00) return 1;
        if (!b.start00) return -1;
        return a.start00.localeCompare(b.start00);
      })
    ];


    // Po vytvoření race0 se toto id přenese do fetchCategories atd., aby se kategorie race0 načetli při otevření nastavení, když je vybraný Event
    if (race0.value && race0.value.id) {
      currentRaceId.value = race0.value.id;
      // načtení race0 ID po otevření nastavení. Poté se race ID aktualizuje podle kliknsutí na záložku v selectRace
      await fetchCategories(race0.value.id);
      await fetchFiles(race0.value.id);
    }
  } catch (error) {
    console.error("There was an error fetching the event and races data:", error);
  }
};


// aktualizace startu 00 pro race i race0
const updateStart00Time = (race) => {
  const raceData = race || race0; // Použij race, pokud je k dispozici, jinak race0
  if (!raceData.start00Time) {
    raceData.start00 = null; // Nastavit na null, pokud je input prázdný
  } else {
    const timeValue = raceData.start00Time.trim();
    const isValidTime = /^([01]\d|2[0-3]):?([0-5]\d)$/.test(timeValue);

    if (isValidTime) {
      raceData.start00 = `${raceData.race_date}T${timeValue}`;
    } else {
      console.error("Invalid time format:", timeValue);
      raceData.start00 = null; // Nebo zobrazit chybu uživateli
    }
  }
};




const validateEntryDeadlines = () => {
  const deadline1 = new Date(race0.value.entry_deadline_1);
  const deadline2 = race0.value.entry_deadline_2 ? new Date(race0.value.entry_deadline_2) : null;
  const eventStartDate = new Date(event.value.event_date_start);

  // Reset time to 00:00:00 for comparison
  const resetTime = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  };

  const deadline1DateOnly = resetTime(deadline1);
  const deadline2DateOnly = deadline2 ? resetTime(deadline2) : null;
  const eventStartDateOnly = resetTime(eventStartDate);

  if (deadline1DateOnly > eventStartDateOnly) {
    errorMessage.value = "1. termín přihlášek musí být před datem nebo mít stejné datum jako datum začátku soutěže.";
    successMessage.value = "";
    return false;
  }

  if (deadline2DateOnly && deadline2DateOnly > eventStartDateOnly) {
    errorMessage.value = "2. termín přihlášek musí být před datem nebo mít stejné datum jako datum začátku soutěže.";
    successMessage.value = "";
    return false;
  }

  if (deadline2DateOnly && deadline2DateOnly < deadline1DateOnly) {
    errorMessage.value = "2. termín přihlášek nesmí být před datem 1. termínu přihlášek.";
    successMessage.value = "";
    return false;
  }

  return true;
};



  const saveEvent = async () => {
  const eventId = route.params.id;

  // Check if entry is open and the first entry deadline for race0 is not set
  if (event.value.entry_is_open === true && (race0.value.entry_deadline_1 === null || race0.value.entry_deadline_1 === '')) {
    errorMessage.value = "Soutěž nelze uložit, protože při spuštění přihlášek musí být známý I. termín přihlášek pro soutěž.";
    console.error(errorMessage.value);
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
    return; // Předčasné ukončení metody, nepokračovat v uložení
  }

  // Validace termínů přihlášek
  if (!validateEntryDeadlines()) {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
    return;
  }

  // Kontrola, zda datum všech etap jsou v rozmezí dat začátku a konce události
  const startDate = new Date(event.value.event_date_start);
  const endDate = new Date(event.value.event_date_end);

  for (let race of races.value) {
    const raceDate = new Date(race.race_date);
    if (raceDate < startDate || raceDate > endDate) {
      errorMessage.value = 'Datum všech etap musí být v rozmezí konání soutěže.';
      return;
    }
  }


  // Aktualizace start00 s přidáním času z inputu
  for (let race of races.value) {
    if (race.start00Time === undefined) {
      // Pokud nebyl zadán žádný čas, ponecháme původní hodnotu
      continue;
    } else if (!race.start00Time || race.start00Time === '') {
      race.start00 = null; // Nastavit na null, pokud není zadán žádný čas
    } else {
      race.start00 = `${race.race_date}T${race.start00Time}`;
    }
  }
  // Kontrola, zda race.start00 obsahuje stejné datum jako race.race_date
  for (let race of races.value) {
    if (race.start00 && race.race_date) {
      const start00Date = race.start00.split('T')[0];
      if (start00Date !== race.race_date) {
        errorMessage.value = 'Datum ve Startu 00 musí být stejné, jako datum etapy.';
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }
    }
  }


  try {
    const response = await apiClient.put(`event/edit/?id=${eventId}`, event.value);
    event.value = response.data;
    localStorage.setItem('successMessage', 'Soutěž byla úspěšně aktualizována');
    router.go(`/soutez/${eventId}/nastaveni`); // aktualizace stránky
    } catch (error) {
    console.error("There was an error updating the event:", error);

    if (error.response && error.response.status === 423) {
      // Pokud status 423, zobraz zprávu z API
      errorMessage.value = error.response.data.error || "Nelze aktualizovat událost kvůli blokovanému stavu.";
    } else {
      // Obecná chybová zpráva
      errorMessage.value = "Nepodařilo se aktualizovat událost.";
    }

    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
    }
  };

const deleteEvent = async () => {
  try {
    const eventId = route.params.id;
    await apiClient.delete(`event/edit/?id=${eventId}`);
    dialogDeleteEvent.value = false; // Close the confirmation dialog
    localStorage.setItem('successMessage', 'Soutěž byla úspěšně smazána.');
    router.push('/'); // Přesměrování uživatele po úspěšném smazání
  } catch (error) {
    console.error("There was an error deleting the race:", error);
    dialogDeleteEvent.value = false; // Close the confirmation dialog
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
    successMessage.value = "";
    if (error.response.status === 423) {
      errorMessage.value = "Nemůžete smazat soutěž, ve které jsou přihlášení závodníci.";
    } else {
      errorMessage.value = "Etapu se nepodařilo smazat.";
    }
  }
};


  const addRace = async () => {
  try {
    const eventId = route.params.id;

    // Kontrola, zda datum závodu je v rozmezí dat začátku a konce události
    const startDate = new Date(event.value.event_date_start);
    const endDate = new Date(event.value.event_date_end);
    const raceDate = new Date(newRace.value.race_date);

    if (raceDate < startDate || raceDate > endDate) {
      errorMessage.value = 'Datum etapy musí být v rozmezí konání soutěže.';
      return;
    }


    const newRaceData = {
      title: newRace.value.title,
      race_date: newRace.value.race_date,
      race_type: newRace.value.race_type,
      event: route.params.id, // ID eventu, ke kterému se etapa přidává
    };
    await apiClient.post(`event/edit/?id=${eventId}`, newRaceData);
    await loadEventAndRacesData();
    newRace.value = {title: '', race_date: '', race_type: ''}; // Resetování formuláře
    successMessage.value = "Etapa byla úspěšně přidána.";
    errorMessage.value = "";
    dialogAddRace.value = false;

  } catch (error) {
    console.error("There was an error adding the race:", error);
    errorMessage.value = "Všechny údaje musí být vyplněné.";
    dialogAddRace.value = true;
  }
};

// Zde se odesílá DELETE požadavek na API
const deleteRace = async (eventId, raceId) => {
  try {
    await apiClient.delete(`event/edit/?race_id=${raceId}`);
    // Úspěšné smazání etapy
    successMessage.value = "Etapa byla úspěšně smazána.";
    await loadEventAndRacesData(); // Znovu načíst data pro aktualizaci UI
  } catch (error) {
    dialogDeleteRace.value = false; // zavřít okno upozornění
    console.error("There was an error deleting the race:", error);
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
    successMessage.value = "";
    if (error.response.status === 423) {
      errorMessage.value = "Nemůžete smazat etapu, ve které jsou přihlášení závodníci.";
    } else {
      errorMessage.value = "Etapu se nepodařilo smazat.";
    }
  }
};


// Načte Race ID ze záložky na kterou se klikne. Pro záložku s Eventem se sejme race0 ID
const selectRace = (raceId) => {
  currentRaceId.value = raceId; // Aktualizujte currentRaceId na vybrané raceId po kliknutí myší na záložku
  fetchCategories(raceId);
  fetchFiles(raceId); // Přiřazední Race ID pro načtení Files
  fetchStartlist(raceId);
};



const fetchCategories = async (raceId) => {
  try {
    const response = await apiClient.get(`categories/?race=${raceId}`);
    categories.value = response.data.map(category => ({
      ...category,
      // K výpisu se přidá příznak pro hromadnou úpravu startovného, aby se neupravovaly hodnoty z databáze, ale jen ty prázdné
      feeFromDatabase: category.fee !== null && category.fee !== '',
      increasedFeeFromDatabase: category.increased_fee !== null && category.increased_fee !== '',
      feeEventFromDatabase: category.fee_event !== null && category.fee_event !== ''
    }));
  } catch (error) {
    console.error("There was an error fetching the categories:", error);
  }
};

// Aktivace tlačítka uložit, jen pokud je vyplněné pole name a option
const NotNameOption = () => {
  return categories.value.every(category => category.name.trim() !== '' && category.option.trim() !== '');
};

const bulkFee = ref(null);
const bulkIncreasedFee = ref(null);
const bulkFeeEvent = ref(null);


// Hromadně zvýšit startovné
const applyBulkFee = () => {
  categories.value.forEach(category => {
    if (!category.feeFromDatabase) {
      category.fee = bulkFee.value;
    }
  });
};

// Hromadně zvýšit zvýšené startovné
const applyBulkIncreasedFee = () => {
  categories.value.forEach(category => {
    if (!category.increasedFeeFromDatabase) {
      category.increased_fee = bulkIncreasedFee.value;
    }
  });
};

// Hromadně zvýšit startovné soutěže
const applyBulkFeeEvent = () => {
  categories.value.forEach(category => {
    if (!category.feeEventFromDatabase) {
      category.fee_event = bulkFeeEvent.value;
    }
  });
};


const addNewCategory = () => {

  const newCategory = {
    id: null, // ID nebude nastaveno pro nově vytvořenou kategorii
    name: '',
    maxAge: null,
    sex: '',
    option: '',
    fee: null,
    increased_fee: null,
    reduced_fee: null,
    race: currentRaceId.value, // race je na základě aktuálního vybraného race.id
  };
  categories.value.push(newCategory); // Add the new category to the reactive list
};


// Pokud je switch 'Přepsat i v etapách' true, zobrazí se dialog pro potvrzení
const dialogCategoryRace0show = () => {
  if (change_allcategories.value) {
    dialogCategoryRace0.value = true;
  } else {
    updateCategory();
  }
};

const updateCategory = async () => {
  try {
    const eventId = route.params.id;
    const updatedCategories = categories.value.filter(c => c.id !== null);
    const newCategories = categories.value.filter(c => c.id === null);

    const payload = {
      event_id: eventId,
      change_allcategories: change_allcategories.value, // proměnná pro hromadné uložení kat. do etap
      updated_categories: updatedCategories,
      new_categories: newCategories,
      categories_to_delete: categoriesToDelete.value,
    };

    const response = await apiClient.put('categories/', payload);
    categoriesToDelete.value = []; // Reset seznamu pro smazání
    dialogCategory.value = false; // Zavřete dialog po úspěšné aktualizaci
    dialogCategoryRace.value = false; // Zavřete dialog po úspěšné aktualizaci
    dialogCategoryRace0.value = false; // Zavřete dialog po úspěšné aktualizaci
    // zobrazení hlášky o úspěchu
    errorMessage.value = "";
    if (response.data.not_deleted && response.data.not_deleted.length > 0) {
      successMessage.value = `Kategorie byly úspěšně aktualizovány, ale některé nebyly smazány kvůli existujícím přihláškám: ${response.data.not_deleted.join(', ')}`;
    } else {
      successMessage.value = "Kategorie byly úspěšně aktualizovány";
    }
    // načtení aktuálních kategorií
    await fetchCategories(currentRaceId.value);

  } catch (error) {
    console.error("Chyba při aktualizaci kategorií", error);
    dialogCategoryRace0show (error);

    // Kontrola na chybu 406 a nastavení errorMessage z odpovědi
    if (error.response && error.response.status === 406) {
      errorMessage.value = error.response.data.error || "Kategorie se nepodařilo uložit kvůli duplicitě.";
    } else {
      errorMessage.value = "Kategorie se nepodařilo uložit.";
    }

    dialogCategory.value = false; // Zavřete dialog po neúspěšné aktualizaci
    dialogCategoryRace.value = false; // Zavřete dialog po neúspěšné aktualizaci
    dialogCategoryRace0.value = false; // Zavřete dialog po neúspěšné aktualizaci
    successMessage.value = "";
  }
};


// uložení kategorií do proměnné pro smazání
const markCategoryForDeletion = (categoryId) => {
  if (!categoriesToDelete.value.includes(categoryId)) {
    categoriesToDelete.value.push(categoryId);
  }
  // Odstraňte kategorii z aktuálně zobrazených kategorií
  categories.value = categories.value.filter(category => category.id !== categoryId);
};


const fetchFiles = async (raceId) => {
  try {
    const response = await apiClient.get(`files/?race_id=${raceId}`);
    files.value = response.data; // Update the files array with the fetched data
  } catch (error) {
    console.error("Nepodařilo se načíst soubory", error);
  }
};

const editFile = (file) => {
  file.editing = true; // Přímo nastavíme vlastnost objektu
};

const updateFiles = async (file) => {
  try {
    const formData = new FormData();
    formData.append('id', file.id); // Přidáváme ID souboru pro jeho jednoznačnou identifikaci na serveru
    formData.append('name', file.name);
    formData.append('specific', file.specific);
    formData.append('url', file.url);

    if (file.newFile) { // Kontrola, zda byl přiložen nový soubor
      formData.append('file', file.newFile);
    }
    // Přidání race_id pouze pokud je explicitně potřeba aktualizovat spojení souboru a závodu
    if (currentRaceId.value) {
      formData.append('race_id', currentRaceId.value);
    }

    await apiClient.put(`files/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    file.editing = false; // zrušení editace souboru
    await fetchFiles(currentRaceId.value); // Obnovení seznamu souborů
    successMessage.value = "Položka byla úspěšně aktualizována";
  } catch (error) {
    console.error("Nepodařilo se aktualizovat soubory", error);
  }
};


const fileError = ref('');  // Reaktivní reference pro chybové zprávy

const handleNewFileChange = (event) => {
  const file = event.target.files[0];  // Získání souboru z vstupního pole
  if (file) {
    if (file.size > 10485760) {  // Kontrola velikosti souboru - 10 MB max
      fileError.value = 'Soubor nesmí být větší než 10 MB.';
    } else {
      fileError.value = '';  // Resetování chybové zprávy, pokud soubor splňuje podmínky
      newFile.value.file = file;

    }
  }
};



const addNewFile = async () => {
  if (!newFile.value.file || !newFile.value.name) {
    alert('Název a soubor jsou povinné.');
    return;
  }

  try {
    const formData = new FormData();
    formData.append('name', newFile.value.name);
    if (newFile.value.specific) formData.append('specific', newFile.value.specific);
    formData.append('location', newFile.value.file);
    formData.append('race_file', currentRaceId.value);

    await apiClient.post('files/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    successMessage.value = "Soubor byl úspěšně nahrán";
    showAddFileSection.value = false; // Close the add file section
    newFile.value = { name: '', specific: '', location: '' }; // Reset the new file object
    await fetchFiles(currentRaceId.value); // Načti soubory
  } catch (error) {
    console.error("Nepodařilo se nahrát soubor", error);
  }
};


const editLink = (file) => {
  editLink.value = { ...file };
  file.editing = true; // zobrazení editačního režimu
};

const deleteFile = async (fileId) => {
  try {
    await apiClient.delete(`files/?id=${fileId}`);
    await fetchFiles(currentRaceId.value); // Obnovení seznamu souborů
    successMessage.value = "Položka byla úspěšně smazána";
    dialogDeleteFile.value = false; // Close any confirmation dialog if open
  } catch (error) {
    console.error("Nepodařilo se smazat soubor", error);
  }
};


 // funkce přidání odkazu. Editace a smazání je pomocí file
const addNewLink = async () => {
  if (!newLink.value.name || !newLink.value.url) {
    alert('Název a URL odkazu jsou povinné.');
    return;
  }

  try {
    const formData = new FormData();
    formData.append('name', newLink.value.name);
    formData.append('url', newLink.value.url);
    formData.append('race_file', currentRaceId.value);
    formData.append('is_link', true); // Přidání, aby bylo jasné, že se jedná o odkaz, nepoužívá se

    await apiClient.post('files/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    // Zpracování úspěšného přidání
    successMessage.value = "Odkaz byl úspěšně přidán";
    showAddLinkSection.value = false; // Zavření sekce přidání odkazu
    newLink.value = { name: '', url: '' }; // Reset the new link object
    await fetchFiles(currentRaceId.value); // Znovu načtení seznamu souborů/odkazů
  } catch (error) {
    console.error("Nepodařilo se přidat odkaz", error);
  }
};


// ****************** //
// ****************** //          *****************
// UBYTOVÁNÍ A STRAVA //        **********************
// ****************** //          *****************
// ****************** //



const days = ref([createNewDay()]);

// Vytvoření nového dne pro jídla
function addNewDay() {
  days.value.push(createNewDay());
}

const accommodations = ref([
  { id: null, name: '', price: '', limit: null, variant: 1, date_from: '', date_to: '', loadedFromAPI: false },
  { id: null, name: '', price: '', limit: null, variant: 2, date_from: '', date_to: '', loadedFromAPI: false },
  { id: null, name: '', price: '', limit: null, variant: 3, date_from: '', date_to: '', loadedFromAPI: false },
  { id: null, name: '', price: '', limit: null, variant: 4, date_from: '', date_to: '', loadedFromAPI: false }
]);

// Příprava ubytování před odesláním dat do API
function prepareAccommodationsData() {
  const preparedAccommodations = [];
  accommodations.value.forEach(acc => {
    // Předpokládáme, že acc.date_from a acc.date_to jsou správně nastaveny
    if (acc.name && acc.price && acc.date_from && acc.date_to) {  // Zkontrolujte, zda jsou všechna potřebná data k dispozici
      preparedAccommodations.push({
        name: acc.name,
        variant: acc.variant,
        price: acc.price,
        id: acc.id,
        limit: acc.limit,
        date_from: acc.date_from, // Nejranější možné datum
        date_to: format(acc.date_to, 'yyyy-MM-dd'), // Nejpozdnější možné datum
        only_all_days: acc.only_all_days,
        loadedFromAPI: acc.loadedFromAPI,
      });
    }
  });
  return preparedAccommodations;
}

// dialog před smazání varianty ubytování
const dialogAccomodations = reactive(accommodations.value.map(() => false));
function showAccomodationDialog(index) {
  dialogAccomodations[index] = true;
}

const fieldErrors = reactive({});

function resetAccommodation(index) {
  const acc = accommodations.value[index];
  acc.name = '';
  acc.price = '';
  acc.limit = null;
  // Následující pole mohou být také resetována, nebo ponechána dle vašich potřeb
  acc.date_from = '';
  acc.date_to = '';
  acc.only_all_days = false;
  submitEntryService()
}

function validateDay(day) {
  let hasError = false;
  for (const meal of day.breakfasts.concat(day.lunches, day.dinners)) {
    if ((meal.name && !meal.price) || (!meal.name && meal.price)) {
      fieldErrors[meal.food_type + meal.variant] = 'Název i cena jsou povinné';
      hasError = true;
    } else {
      fieldErrors[meal.food_type + meal.variant] = '';
    }
  }

  if ((day.breakfasts.concat(day.lunches, day.dinners).some(meal => meal.name || meal.price)) && !day.date) {
    fieldErrors['date' + day.date] = 'Datum je povinné, pokud jsou vyplněny jídla';
    hasError = true;
  } else {
    fieldErrors['date' + day.date] = '';
  }

  return hasError;
}

function validateAccommodation() {
  let hasError = false;
  accommodations.value.forEach((acc, index) => {
    // Zkontrolujte, zda je některé z polí vyplněno
    const isAnyFieldFilled = acc.name || acc.price || acc.date_from || acc.date_to;

    // Zkontrolujte, zda jsou všechna požadovaná pole vyplněna
    const areAllFieldsFilled = acc.name && acc.price && acc.date_from && acc.date_to;

    if (isAnyFieldFilled && !areAllFieldsFilled) {
      // Nastaví chybovou zprávu, pokud je některé z polí vyplněno, ale ne všechna
      fieldErrors[`acc${index}`] = 'Název, cena a datumy jsou povinné';
      hasError = true;
    } else {
      // Vymazání jakýchkoliv dřívějších chyb, pokud jsou všechna data nyní správně
      fieldErrors[`acc${index}`] = '';
    }
  });
  return hasError;
}

// Vytvoření nového dne pro vytvoření jídel
function createNewDay() {
  return {
    date: '',
    breakfasts: [{ name: '', price: '', food_type: 'sn', variant: 1 }],
    lunches: [
      { name: '', price: '', food_type: 'ob', variant: 1 },
      { name: '', price: '', food_type: 'ob', variant: 2 },
      { name: '', price: '', food_type: 'ob', variant: 3 },
      { name: '', price: '', food_type: 'ob', variant: 4 }
    ],
    dinners: [
      { name: '', price: '', food_type: 've', variant: 1 },
      { name: '', price: '', food_type: 've', variant: 2 },
      { name: '', price: '', food_type: 've', variant: 3 },
      { name: '', price: '', food_type: 've', variant: 4 }
    ]
  };
}

// Načtení dat pro odeslání do api ve formátu date: lunces(), breakfasts(), dinners()
function prepareFoodData() {
  return days.value.map(day => ({
    date: day.date || null,
    breakfasts: day.breakfasts.map(b => ({ ...b, id: b.id || null, date: day.date || null })),
    lunches: day.lunches.map(l => ({ ...l, id: l.id || null, date: day.date || null })),
    dinners: day.dinners.map(d => ({ ...d, id: d.id || null, date: day.date || null }))
  }));
}

// odeslání dat EntryServices do API se zobrazením chyb před odesláním, pokud nejsou vyplněná pole
const submitEntryService = async () => {

  let hasErrors = false;
  // validace jídel
  days.value.forEach(day => {
    if (validateDay(day)) {
      hasErrors = true;
    }
  });

    // Validace ubytování
  if (validateAccommodation()) {
    hasErrors = true;
  }

  if (hasErrors) {
    console.error('Formulář obsahuje chyby a nemůže být odeslán.');
    return; // Ukončí funkci, pokud jsou chyby
  }

  const payload = {
    accommodations: prepareAccommodationsData(),
    food: prepareFoodData()
  };

  try {
    const eventId = route.params.id; // event ID z URL adresy
    const response = await apiClient.post(`race_service/?event_id=${eventId}`, payload);
    successMessage.value = "Ubytování a jídlo byly úspěšně uloženy";
    errorMessage.value = "";
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
    console.log('Data successfully sent to the API:', response);
    fetchRaceServiceData()
  } catch (error) {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
    if (error.response.status === 423) {
      successMessage.value = "";
      errorMessage.value = "Položky ubytování nebo jídla nemohou být kvůli přihlášeným závodníkům smazány nebo upraveny.";
      fetchRaceServiceData()
    } else {
      errorMessage.value = "Jídlo a ubytování se nepodařilo uložit";
    }
  }
};

// Načtení Services pro event
async function fetchRaceServiceData() {
  const eventId = route.params.id;  // ID události z URL
  try {
    const response = await apiClient.get(`race_service/?event_id=${eventId}`);
    if (response.status === 200) {
      accommodations.value = processAccommodationData(response.data.accommodations);
      availableAccommodations.value = groupByDate(response.data.accommodations); // pro editaci entries
      if (response.data.food && Object.keys(response.data.food).length > 0) {
        days.value = processFoodData(response.data.food);
        availableFood.value = groupFoodByDate(response.data.food); // pro editaci entries


      } else {
        days.value = [createNewDay()]; // Vytvoří prázdný den, pokud nejsou načtena žádná data o jídle
        availableFood.value = {};

      }
    } else {
      console.error('Failed to fetch data:', response.status);
    }
  } catch (error) {
    console.error('Error fetching Race Service data:', error);
  }
}


// Zpracování ubytování z API na frontend
function processAccommodationData(accommodationsData) {
  const processedAccommodations = { // defaultně 4 varianty ubytování
    1: { id: null, name: '', price: '', limit: null, variant: '1', date_from: '', date_to: '', only_all_days: false, loadedFromAPI: false },
    2: { id: null, name: '', price: '', limit: null, variant: '2', date_from: '', date_to: '', only_all_days: false, loadedFromAPI: false },
    3: { id: null, name: '', price: '', limit: null, variant: '3', date_from: '', date_to: '', only_all_days: false, loadedFromAPI: false },
    4: { id: null, name: '', price: '', limit: null, variant: '4', date_from: '', date_to: '', only_all_days: false, loadedFromAPI: false }
  };

  accommodationsData.forEach(acc => {
    const variant = acc.variant || '1';  // Přiřazení varianty, pokud není specifikováno, použijeme '1'
    let variantData = processedAccommodations[variant];

    // Agregace dat do jedné varianty ubytování, vytvoření date from a date to
    if (!variantData.date_from || new Date(acc.date) < new Date(variantData.date_from)) {
      variantData.date_from = acc.date;
    }
    if (!variantData.date_to || new Date(acc.date) > new Date(variantData.date_to)) {
      variantData.date_to = acc.date;
    }
    variantData.id = acc.id;
    variantData.name = acc.name;
    variantData.price = acc.price;
    variantData.limit = acc.limit || null;  // null kvůli integer field
    variantData.only_all_days = acc.only_all_days;
    variantData.loadedFromAPI = true;  // Označení, že tato varianta byla načtena z API a zakáže se manipulace s daty
  });

  return Object.values(processedAccommodations);  // Vrátí pole s vždy čtyřmi variantami ubytování
}

// zpracování jídla tak, aby se rozdělilo a zobrazilo na 3 sloupce a více variantách
function processFoodData(foodData) {
  const dayList = [];
  for (const [date, meals] of Object.entries(foodData)) {
    dayList.push({
      date,
      breakfasts: fillMealsArray(meals.breakfasts, 1, 'sn'), // výběr z jedné varianty snídaně
      lunches: fillMealsArray(meals.lunches, 4, 'ob'),
      dinners: fillMealsArray(meals.dinners, 4, 've')
    });
  }
  return dayList;
}

function fillMealsArray(meals, count, foodType) {
  let result = meals && meals.length > 0 ? meals : [];
  while (result.length < count) {
    result.push({ name: '', price: '', food_type: foodType, variant: result.length + 1 });
  }
  return result;
}




///////////////////////////////////
//////                     ////////                     ************
////// Správa přihlášených ////////                  *******************
//////                     ///////                      *************
///////////////////////////////////

const entries = ref([
  {
    id: '',
    surname: '',
    first_name: '',
    si_number: null,
    category: '',
    note: ''
    },
  // Přidejte další položky podle potřeby
]);

const emails = ref([]);
const showSnackbar = ref(false);



// přiřazení ubytování uživatele k dostupným ubytováním v soutěži
function initializeAccommodationData() {
  if (!selectedEntry.value.accommodation) {
    selectedEntry.value.accommodation = {};
  }
  Object.keys(availableAccommodations.value).forEach(date => {
    // Přiřazení již vybraných ubytování na základě ID z `selectedEntry.race_service`
    const selectedAccommodations = availableAccommodations.value[date].filter(acc => selectedEntry.value.race_service.includes(acc.id));
    selectedEntry.value.accommodation[date] = selectedAccommodations.map(acc => acc.id);
  });
}

// přiřřazení stravy uživatele ke stravě, která je v soutěži k dispozici
function initializeFoodData() {
  if (!selectedEntry.value.food) {
    selectedEntry.value.food = {};
  }
  Object.keys(availableFood.value).forEach(date => {
    if (!selectedEntry.value.food[date]) {
      selectedEntry.value.food[date] = { breakfasts: [], lunches: [], dinners: [] };
    }
    const selectedBreakfasts = availableFood.value[date].breakfasts.filter(food => selectedEntry.value.race_service.includes(food.id));
    const selectedLunches = availableFood.value[date].lunches.filter(food => selectedEntry.value.race_service.includes(food.id));
    const selectedDinners = availableFood.value[date].dinners.filter(food => selectedEntry.value.race_service.includes(food.id));

    selectedEntry.value.food[date].breakfasts = selectedBreakfasts.map(food => food.id);
    selectedEntry.value.food[date].lunches = selectedLunches.map(food => food.id);
    selectedEntry.value.food[date].dinners = selectedDinners.map(food => food.id);
  });
}


const allRaces = ref([]);

const getEntryRaceData = (entry, raceId) => {
  let raceData = entry.entry_race_data.find(data => data.race_id === raceId);
  if (!raceData) {
    raceData = { race_id: raceId, category_id: null, si_number: null };
    entry.entry_race_data.push(raceData);
  }
  return raceData;
};




const dialogEditEntry = ref(false);
const openEditDialog = (entry) => {
  selectedEntry.value = { ...entry, entry_race_data: entry.entry_race_data.map(data => ({ ...data })) };
  initializeAccommodationData();
  initializeFoodData();
  dialogEditEntry.value = true;
};

const dialogDeleteEntry = ref(false);
const openDeleteDialog = (entry) => {
  selectedEntry.value = entry;

  dialogDeleteEntry.value = true;
};



const sortByEntries = [{ key: 'surname', order: 'asc' }]; // Klíč pro řazení

const selectedEntry = ref(null);

const entryHeaders = ref([
  { title: 'Příjmení', value: 'surname', sortable: true},
  { title: 'Jméno', value: 'first_name', sortable: true },
  { title: 'Index', value: 'index', sortable: true },
  { title: 'Číslo čipu', value: 'si_number', sortable: true },
  { title: 'Kategorie', value: 'category', sortable: true },
  { title: 'Termín', value: 'termin_prihl', sortable: true, width: '20px' },
  { title: 'Poznámka', value: 'note', sortable: true },
  { title: 'Akce', value: 'actions', sortable: false, width: '100px', align: 'center'}
]);




const availableAccommodations = ref([]);

// Funkce pro seskupení ubytování podle dat
function groupByDate(accommodations) {
  const grouped = {};
  accommodations.forEach(accommodation => {
    if (!grouped[accommodation.date]) {
      grouped[accommodation.date] = [];
    }
    grouped[accommodation.date].push(accommodation);
  });
  return grouped;
}

const availableFood = ref({});

function groupFoodByDate(foodItems) {
  return Object.entries(foodItems).reduce((grouped, [date, meals]) => {
    grouped[date] = {
      breakfasts: meals.breakfasts?.filter(food => food.price !== "") || [],
      lunches: meals.lunches?.filter(food => food.price !== "") || [],
      dinners: meals.dinners?.filter(food => food.price !== "") || []
    };
    return grouped;
  }, {});
}

// Funkce pro resetování vyhledávání
const resetSearch = () => {
  searchSurname.value = '';
  fetchEntries();
};

const searchSurname = ref('');
const isLoadingEntries = ref(true);


const fetchEntries = async () => {
  const eventId = route.params.id;

  try {
    let query = `entries/?event_id=${eventId}`;
    if (searchSurname.value) {
      query += `&surname=${searchSurname.value}`;
    }
    const response = await apiClient.get(query);
    emails.value = response.data.map(entry => entry.email);
    entries.value = response.data.map(entry => {
      allRaces.value.forEach(race => {
        if (!entry.entry_race_data.some(data => data.race_id === race.id)) {
          entry.entry_race_data.push({
            race_id: race.id,
            category_id: null,
            category_name: 'nepřihlášen',
            si_number: null
          });
        }
      });
      entry.entry_race_data.sort((a, b) => {
        if (a.category_name === 'nepřihlášen') return -1;
        if (b.category_name === 'nepřihlášen') return 1;
        return a.category_name.localeCompare(b.category_name);
      });
      return entry;
    });

  } catch (error) {
    console.error("Error fetching entries:", error);
  } finally {
    isLoadingEntries.value = false;  // Loading skončí po úspěšném načtení nebo při chybě
  }
};

// uložení emailů do schránky počítače
const saveEmailsToClipboard = async () => {
  await fetchEntries(); // Fetch the entries first
  const emailString = emails.value.join(', ');
  navigator.clipboard.writeText(emailString).then(() => {
    console.log('Emails copied to clipboard:', emailString);
    showSnackbar.value = true; // Show snackbar alert
  }).catch(err => {
    console.error('Failed to copy emails to clipboard:', err);
  });
};

const editEntry = async () => {
  try {
    // Filtrujeme pouze ty závodní data, které mají vyplněnou kategorii
    selectedEntry.value.entry_race_data = selectedEntry.value.entry_race_data.filter(data => data.category_id !== null);

     // Sestavení seznamu ID ubytování z objektu accommodation pro každý den
    const accommodationIds = Object.values(selectedEntry.value.accommodation).flat().filter(id => id !== null);

    // Sestavení seznamu ID stravy z objektu food pro každý den
    const foodIds = Object.values(selectedEntry.value.food).flatMap(day =>
      ['breakfasts', 'lunches', 'dinners'].flatMap(mealType => day[mealType] || [])
    ).filter(id => id !== null);

    // Kombinace ID ubytování a stravy do race_service
    const raceServiceIds = [...accommodationIds, ...foodIds];

    // Příprava payloadu pro odeslání na server
    const payload = {
      ...selectedEntry.value,
      race_service: raceServiceIds  // Přidání aktualizovaného seznamu race_service
    };

    await apiClient.put(`entries/?entry_id=${selectedEntry.value.id}`, payload);
    fetchEntries();
    fetchRaceServiceData();

    successMessage.value = "Přihláška byla úspěšně aktualizována.";
    errorMessage.value = "";
    dialogEditEntry.value = false;
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
  } catch (error) {
    console.error("There was an error updating the entry:", error);
    if (error.response && error.response.status === 423) {
      errorMessage.value = "Limit pro tuto variantu ubytování byl překročen.";
    } else {
      errorMessage.value = "Nepodařilo se aktualizovat přihlášku.";
    }
    successMessage.value = "";
    dialogEditEntry.value = true;

  }
};


const deleteEntry = async () => {
  try {
    await apiClient.delete(`entries/?entry_id=${selectedEntry.value.id}`);
    fetchEntries();
    successMessage.value = "Přihláška byla úspěšně smazána.";
    dialogDeleteEntry.value = false;
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
  } catch (error) {
    console.error("There was an error deleting the entry:", error);
    errorMessage.value = "Nepodařilo se smazat přihlášku.";
    dialogDeleteEntry.value = false;
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy

  }
};

const accommodationVariants = computed(() => {
  const variants = {};
  Object.values(availableAccommodations.value).forEach(dateAccommodations => {
    dateAccommodations.forEach(accommodation => {
      if (!variants[accommodation.variant]) {
        variants[accommodation.variant] = {
          variant: accommodation.variant,
          count: accommodation.count,
          limit: accommodation.limit
        };
      }
    });
  });
  return Object.values(variants).filter(variant => variant.count > 0);
});
// Automaticky spouští fetchEntries, když se změní currentRaceId
watch(currentRaceId, async (newRaceId) => {
  if (newRaceId) {
    await fetchEntries();
  }
});



///////////////////////////////////
//////                     ////////                     ************
//////   Správa startovek  ////////                  *******************
//////                     ///////                      *************
///////////////////////////////////

const dialogAddStartlist = ref(false);
const uploadedStartlistFile = ref(null);
const errorStartlistMessage = ref('');
const errorStartlistSendMessage = ref('');

const isFileValid = ref(false);
const successStartlistMessage = ref('');
const stepStart = ref(1);
const totalStartSteps = 2;

const StartlistSortBy = [{key: 'category_name', order: 'asc'}]; // Klíč pro řazení

const startlist = ref([]);

const GetStartlistHeaders = ref([

      { title: 'Příjmení', value: 'surname', sortable: true },
      { title: 'Jméno', value: 'first_name', sortable: true },
      { title: 'Čas startu', value: 'start_time', sortable: true },
      { title: 'Kat.', value: 'category_name', sortable: true },
      { title: 'Index', value: 'index', sortable: true },
      { title: 'Číslo čipu', value: 'si_number', sortable: true },
      { title: 'Oddíl', value: 'club_shortcut', sortable: true },
      { title: 'Akce', value: 'actions', width: '100px', align: 'center', sortable: false },

    ]);

const fetchStartlist = async () => {
    try {
      const response = await apiClient.get(`/startlist/?race_id=${currentRaceId.value}`);
      startlist.value = response.data;
    } catch (error) {
      console.error('Chyba při načítání dat:', error);
    }
  };



const startlistHeaders = [
  { title: 'St. č.', value: 'bib_number', sortable: true, width: '90px', align: 'center' },
  { title: 'Příjmení', value: 'surname', sortable: true },
  { title: 'Jméno', value: 'first_name', sortable: true },
  { title: 'Start relativní', value: 'start_time_relative', sortable: true },
  { title: 'Čas reálný', value: 'start_time_real', sortable: true },
  { title: 'Index', value: 'index', align: 'center', sortable: true },
  { title: 'Kat.', value: 'category_name', sortable: true },
  { title: 'Čip', value: 'si_number', sortable: true },
  { title: 'Akce', value: 'actions', width: '100px', align: 'center', sortable: false },
];

function openStartlistStepperDialog() {
  dialogAddStartlist.value = true;
}

const handleFileUpload = (event) => {
  errorStartlistMessage.value = '';
  successStartlistMessage.value = '';
  const file = event.target.files[0];

  if (file && file.name && file.name.endsWith('.csv')) {
    uploadedStartlistFile.value = file;
    isFileValid.value = true;
  } else {
    uploadedStartlistFile.value = null;
    isFileValid.value = false;
    errorStartlistMessage.value = 'Vyberte prosím platný soubor ve formátu .csv';
  }
};

const startlistEntryOk = ref([]);
const startlistEntryCreate = ref([]);
const startlistEntryEmpty = ref([]);
const combinedStarts = ref([]);

const updateCombinedStarts = () => {
  combinedStarts.value = [
    ...startlistEntryOk.value,
    ...startlistEntryCreate.value,
    ...startlistEntryEmpty.value,
  ];
  console.log('Updated combinedStarts:', combinedStarts.value); // Debugging
};

const selectedStart = ref(null);
const dialogEditStart = ref(false);
const dialogDeleteStart = ref(false);

// převod mm:ss na hh:mm:ss aby se to zobrazilo v input field type=time
const convertToTimeFormat = (timeString) => {
  if (!timeString) return '';

  const parts = timeString.split(':');

  if (parts.length === 1) {
    // Formát je jen minuty (např. 125)
    const minutes = parseInt(parts[0], 10);
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${String(hours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}`;
  } else if (parts.length === 2) {
    // Formát je MM:SS (např. 125:30)
    const minutes = parseInt(parts[0], 10);
    const seconds = parseInt(parts[1], 10);
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${String(hours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }

  return timeString; // Pokud je už ve správném formátu HH:MM:SS
};

// převod hh:mm:ss na mm:ss
const convertToApiFormat = (timeString) => {
  if (!timeString) return '';

  const parts = timeString.split(':');
  const hours = parseInt(parts[0], 10);
  const minutes = parseInt(parts[1], 10);
  const seconds = parts.length === 3 ? parseInt(parts[2], 10) : 0;

  // Pokud jsou hodiny přítomné, převedeme na celkové minuty
  const totalMinutes = hours * 60 + minutes;

  if (seconds > 0) {
    return `${totalMinutes}:${String(seconds).padStart(2, '0')}`;
  } else {
    return String(totalMinutes);
  }
};


// Funkce pro formátování času do HH:MM:SS
const formatTime = (timeString) => {
  if (!timeString) return ''; // Pokud není čas k dispozici, vraťte prázdný řetězec

  // Rozdělení časového řetězce na hodiny, minuty a sekundy
  const [hours, minutes, seconds] = timeString.split(':');

  // Ujistěte se, že jsou hodiny a minuty ve správném formátu (dvouciferné)
  const formattedTime = [
    hours.padStart(2, '0'),
    minutes.padStart(2, '0'),
    seconds ? seconds.padStart(2, '0') : '00'  // Zajistí, že sekundy budou také dvouciferné nebo doplněné nulou
  ].join(':');

  return formattedTime; // Vraťte čas ve formátu HH:MM:SS
};

const openEditStartDialog = (start) => {
  selectedStart.value = {
    ...start,
    start_time_real: formatTime(start.start_time_real),
    start_time_relative: convertToTimeFormat(start.start_time_relative)
  };
  dialogEditStart.value = true;
};


const saveEditStart = () => {

  // převod naformátovaných dat na mm:ss do api
  const formattedRelativeTime = convertToApiFormat(selectedStart.value.start_time_relative);
  // Nastavení formátovaných hodnot
  selectedStart.value.start_time_relative = formattedRelativeTime;

  let startIndex = -1;

  // Vyhledání podle indexu, pokud existuje
  if (selectedStart.value.index) {
    startIndex = combinedStarts.value.findIndex(
      (item) => item.index === selectedStart.value.index
    );
  }

  // Pokud index neexistuje nebo nebyl nalezen, vyhledání podle kombinace jména a příjmení
  if (startIndex === -1) {
    startIndex = combinedStarts.value.findIndex(
      (item) =>
        item.first_name.toLowerCase() === selectedStart.value.first_name.toLowerCase() &&
        item.surname.toLowerCase() === selectedStart.value.surname.toLowerCase()
    );
  }

  // Pokud byl záznam nalezen, aktualizujeme data
  if (startIndex !== -1) {
    combinedStarts.value[startIndex] = { ...selectedStart.value };

    // Aktualizace jednotlivých seznamů
    startlistEntryOk.value = combinedStarts.value.filter((item) =>
      startlistEntryOk.value.some(
        (okItem) => okItem.index === item.index ||
        (okItem.first_name.toLowerCase() === item.first_name.toLowerCase() &&
         okItem.surname.toLowerCase() === item.surname.toLowerCase())
      )
    );

    startlistEntryCreate.value = combinedStarts.value.filter((item) =>
      startlistEntryCreate.value.some(
        (createItem) => createItem.index === item.index ||
        (createItem.first_name.toLowerCase() === item.first_name.toLowerCase() &&
         createItem.surname.toLowerCase() === item.surname.toLowerCase())
      )
    );

    startlistEntryEmpty.value = combinedStarts.value.filter((item) =>
      startlistEntryEmpty.value.some(
        (emptyItem) => emptyItem.index === item.index ||
        (emptyItem.first_name.toLowerCase() === item.first_name.toLowerCase() &&
         emptyItem.surname.toLowerCase() === item.surname.toLowerCase())
      )
    );
  }

  // Zavření dialogu po uložení
  dialogEditStart.value = false;
};


const openDeleteStartDialog = (start) => {
  selectedStart.value = start;
  dialogDeleteStart.value = true;
};

const deleteStart = () => {
  const startIndex = combinedStarts.value.findIndex(
    (item) => item.index === selectedStart.value.index
  );
  if (startIndex !== -1) {
    combinedStarts.value.splice(startIndex, 1);
    startlistEntryOk.value = combinedStarts.value.filter(
      (item) => startlistEntryOk.value.some(okItem => okItem.index === item.index)
    );
    startlistEntryCreate.value = combinedStarts.value.filter(
      (item) => startlistEntryCreate.value.some(createItem => createItem.index === item.index)
    );
    startlistEntryEmpty.value = combinedStarts.value.filter(
      (item) => startlistEntryEmpty.value.some(emptyItem => emptyItem.index === item.index)
    );
  }
  dialogDeleteStart.value = false;
};



// Funkce pro nahrání souboru a zpracování do response k editaci
const uploadStartlistFile = async () => {
  if (uploadedStartlistFile.value) {
    const formData = new FormData();
    formData.append('file', uploadedStartlistFile.value);

    try {
      const response = await apiClient.post(`startlist/?race_id=${currentRaceId.value}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      successStartlistMessage.value = 'Soubor byl úspěšně nahrán';
      errorStartlistMessage.value = '';

      // Zpracování dat z response
      startlistEntryOk.value = response.data.startlist_entry_ok;
      startlistEntryCreate.value = response.data.startlist_entry_create;
      startlistEntryEmpty.value = response.data.startlist_entry_empty;

      updateCombinedStarts(); // Aktualizace tabulky po načtení dat

    } catch (error) {
      console.error('Chyba při nahrávání souboru:', error);

      // Ošetření chyby 406 a dalších specifických chybových zpráv
      if (error.response && error.response.status === 406) {
        errorStartlistMessage.value = `Chyba: ${error.response.data.error || 'Neplatná data v souboru.'}`;
      } else {
        errorStartlistMessage.value = 'Chyba při nahrávání souboru na server.';
      }

      successStartlistMessage.value = '';
    }
  }
};
// Watcher pro sledování změn v combinedStarts
watch(combinedStarts, (newVal) => {
  console.log('combinedStarts changed:', newVal); // Debugging
});

// Objekt pro uložení barev přiřazených řádkům při jejich inicializaci
const rowColors = ref({});

const setRowProps = ({ item }) => {
  const itemIndex = item.index || ''; // Získání hodnoty indexu
  const itemName = `${item.surname.toLowerCase()} ${item.first_name.toLowerCase()}`; // Kombinace příjmení a jména

  // Pokud již existuje barva pro daný řádek, vraťte ji
  if (rowColors.value[itemIndex] || rowColors.value[itemName]) {
    return { class: rowColors.value[itemIndex] || rowColors.value[itemName] };
  }

  console.log('Checking row props for:', itemIndex, itemName);

  let rowClass = '';

  if (itemIndex) {
    // Pokud je přítomný index, kontrolujeme podle indexu
    if (startlistEntryOk.value.some(okItem => okItem.index === itemIndex)) {
      rowClass = 'bg-ok';
    } else if (startlistEntryCreate.value.some(createItem => createItem.index === itemIndex)) {
      rowClass = 'bg-create';
    } else if (startlistEntryEmpty.value.some(emptyItem => emptyItem.index === itemIndex)) {
      rowClass = 'bg-empty';
    }
    rowColors.value[itemIndex] = rowClass; // Uložení barvy pro tento řádek podle indexu
  } else {
    // Pokud index není přítomný, kontrolujeme podle kombinace příjmení a jména
    if (startlistEntryOk.value.some(okItem => `${okItem.surname.toLowerCase()} ${okItem.first_name.toLowerCase()}` === itemName)) {
      rowClass = 'bg-ok';
    } else if (startlistEntryCreate.value.some(createItem => `${createItem.surname.toLowerCase()} ${createItem.first_name.toLowerCase()}` === itemName)) {
      rowClass = 'bg-create';
    } else if (startlistEntryEmpty.value.some(emptyItem => `${emptyItem.surname.toLowerCase()} ${emptyItem.first_name.toLowerCase()}` === itemName)) {
      rowClass = 'bg-empty';
    }
    rowColors.value[itemName] = rowClass; // Uložení barvy pro tento řádek podle kombinace příjmení a jména
  }

  return { class: rowClass };
};


const closeStartlistDialog = () => {
  dialogAddStartlist.value = false;
  errorStartlistMessage.value = '';
  errorStartlistSendMessage.value = '';
};

// odeslání JSON pro uložení startů
const saveStartData = async () => {
  try {
    await apiClient.post(
      `startlist/?race_id=${currentRaceId.value}&valid=True`,
      JSON.stringify(combinedStarts.value),  // Převod dat na JSON formát
      {
        headers: {
          'Content-Type': 'application/json',  // Nastavení správné hlavičky
        },
      }
    );
    successMessage.value = 'Data o startech úspěšně uložena';
    errorStartlistSendMessage.value = ""
    dialogAddStartlist.value = false;  // Zavřít dialog po úspěšném odeslání
    combinedStarts.value = [];  // Vynulovat obsah combinedStarts po úspěšném uložení
    rowColors.value = {}; // Resetování barev při úspěšném odeslání
    successStartlistMessage.value = '';
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy

    fetchEntries();
    fetchStartlist();

  } catch (error) {
    console.error('Chyba při odesílání dat:', error);

    // Kontrola kódu 406 a zobrazení specifické chyby
    if (error.response && error.response.status === 406 && error.response.data && Array.isArray(error.response.data.error)) {
      errorStartlistSendMessage.value = error.response.data.error; // Přiřadit celé pole chybových zpráv
      console.log('Error message:', errorStartlistSendMessage.value); // Debugging
    } else {
      errorStartlistSendMessage.value = ['Data pro startovku se nepodařilo odeslat.']; // Přiřadit jako pole
      console.log('Error message:', errorStartlistSendMessage.value);
    }
  }
};


const dialogDeleteAllStartTimes = ref(false);

// Funkce pro otevření dialogu pro smazání všech start_time
const openDeleteAllStartTimesDialog = () => {
  dialogDeleteAllStartTimes.value = true;
};

// Funkce pro mazání všech start_time
const deleteAllStartTimes = async () => {
  try {
    await apiClient.delete(`/startlist/?race_id=${currentRaceId.value}`);
    dialogDeleteAllStartTimes.value = false;
    successMessage.value = 'Všechny startovní časy byly úspěšně smazány.';
    fetchStartlist(); // Obnovit startovní listinu po smazání
  } catch (error) {
    console.error('Chyba při mazání startovních časů:', error);
    errorMessage.value = 'Nepodařilo se smazat startovní časy.';
  }
};


// Funkce pro otevření dialogu pro mazání jednoho startu
const openDeleteGetStartDialog = (start) => {
  selectedStart.value = start;
  dialogDeleteStart.value = true;
};


// Funkce pro mazání jednoho start_time podle competitor_id
const deleteGetStart = async () => {
  try {
    await apiClient.delete(`/startlist/?race_id=${currentRaceId.value}&competitor_id=${selectedStart.value.competitor}`);
    dialogDeleteStart.value = false;
    successMessage.value = 'Startovní čas byl úspěšně smazán.';
    fetchStartlist(); // Obnovit startovní listinu po smazání
  } catch (error) {
    console.error('Chyba při mazání startovního času:', error);
    errorMessage.value = 'Nepodařilo se smazat startovní čas.';
  }
};


</script>



<style scoped>

/** Zmenšení mezer mezi jednotlivými prvky item listu */
#app .v-list-item--density-default {
  min-height: 35px;
}

td {
  min-width: 160px;
}

.dialog-over-dialog .v-dialog__content {
  z-index: 202 !important; /* Standardní z-index pro dialogy Vuetify je 200 */
}

/* zmenšení margin, aby se do okna vešel rám oken */
.v-row {
  margin:-10px;
}

.background-color-1 {
  background-color: #ffffff; /* Light gray background for even days */
}

.background-color-2 {
  background-color: #f6f9ff; /* Light blue background for odd days */
}

@media (min-width: 600px) {
    .v-btn {
      width: auto !important;
    }
  }


  .first-row {
  background-color: #e8efff;
  border-radius: 4px;
  padding: 0 5px 0 5px;
}

/* Stylování vlastího btn pro odkaz na správu všech kategorií hromadně */
.custom-btn {
  display: inline-block;
  width: 100%;
  padding: 8px 16px;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  text-transform: uppercase;
  line-height: 24px;
  color: white;
  background-color:  rgb(24, 103, 192); /* Barva pozadí, může být změněna */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none; /* Odstraní podtržení odkazu */
  white-space: normal; /* Umožňuje zalamování textu */
  word-wrap: break-word; /* Zajišťuje, že dlouhá slova se zalamují */
}

.custom-btn:hover {
  background-color: #1565C0; /* Barva pozadí při najetí myší, může být změněna */
}

:deep(.bg-ok) {
  background-color: #e8f5e9!important; /* Světle zelená */
}

:deep(.bg-create) {
  background-color: #fff3e0!important; /* Světle oranžová */
}

:deep(.bg-empty) {
  background-color: #ffebee!important; /* Světle červená */
}


/* .v-input {
  max-height: 55px; /* Nastavení minimální výšky */
 /* padding: 4px 12px; /* Nastavení paddingu */
/*} */
</style>
