<template>
  <v-container>
    <v-card>
      <v-card-title>{{ account.first_name }} {{ account.surname }} {{ account.index }}</v-card-title>
      <v-card-text>
        <div><b>ID:</b> {{ account.id }}</div>
        <div v-if="showEmail"><b>Email:</b> {{ account.email }}</div>
        <div v-if="account"><b>Poslední aktivita:</b> {{ $formatDateTime(account.last_activity) }}</div>
        <div v-if="account.groups && account.groups.length > 0">
          <b>Pravomoce: </b>
          <span v-for="(group, index) in account.groups" :key="group">
            {{ group === 'organizer' ? 'organizátor' : group === 'club_manager' ? 'vedoucí oddílu' : group }}<span v-if="index < account.groups.length - 1">, </span>
          </span>
        </div>

    </v-card-text>
      <v-data-table density="comfortable" :headers="headers" :items="entries" class="elevation-1" :items-per-page="50" :sort-by="sortBy" :loading="loading" loading-text="Načítám data..."
>
        <template v-slot:item="{ item }">
    <tr :class="{ 'upcoming': isFutureDate(item.race_date) }">
      <td>{{ $formatDate(item.race_date) }}</td>
      <td>
        <router-link :to="'/soutez/' + item.event_id">{{ item.race_event_title }}</router-link>
      </td>
      <td>{{ item.race_title }}</td>
      <td>{{ item.category_name }}</td>
    </tr>
  </template>
  <template v-slot:no-data>
       
    {{ account.first_name }} ještě nikde nebyl<span v-if="account.sex == 'F'">a</span> :(
        
      </template>

      </v-data-table>

    </v-card>
  </v-container>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import apiClient from '/api.js';
import { useRoute } from 'vue-router';
import { useAuthStore } from '/auth.js';

const authStore = useAuthStore();
const route = useRoute();
const accountId = route.params.id; // Získání ID uživatele z URLconst account = ref({});
const entries = ref([]);
const account = ref({});
const sortBy = [{ key: 'race_date', order: 'desc' }]; // Klíč pro řazení
const headers = [
  { title: 'Datum', key: 'race_date', sortable: true },
  { title: 'Soutěž', key: 'race_event_title', sortable: true },
  { title: 'Etapa', key: 'race_title', sortable: true },
  { title: 'Kategorie', key: 'category_name', sortable: true }
];

const loading = ref(true); 

const showEmail = computed(() => authStore.isLoggedIn && account.value.allow_email); // pravidlo pro viditelnost emailu

const isFutureDate = (dateString) => {
  const currentDate = new Date();
  const raceDate = new Date(dateString);
  return raceDate > currentDate;
};

// Načítání údajů účtu
const loadAccountData = async (accountId) => {
  try {
    const response = await apiClient.get(`account/?id=${accountId}`);
    account.value = response.data[0];
  } catch (error) {
    console.error("There was an error fetching the account data:", error);
  }
};

// Načítání entries uživatele
const loadUserEntries = async (accountId) => {
  try {
    const response = await apiClient.get(`account/${accountId}/entries`);
    entries.value = response.data.sort((a, b) => new Date(b.race_date) - new Date(a.race_date));
  } catch (error) {
    console.error('Chyba při načítání entries:', error);
  } finally {
    loading.value = false;  // Nastavíme loading na false po načtení dat
  }

};

onMounted(() => {
  loadAccountData(accountId);
  loadUserEntries(accountId);
});
</script>

<style scoped>
.upcoming {
  background-color: rgba(34, 212, 93, 0.15) !important;
}
</style>
