<template>
  <v-container class="d-flex justify-center">
    <v-col cols="12" md="9">
    <v-data-table
      :headers="clubHeaders"
      :items="clubs"
      item-key="id"
      class="elevation-1"
      :sort-by="['club_shortcut']"
      :sort-desc="[false]"
      :items-per-page="-1"
    >
    <template #[`item.club_name`]="{ item }">
      <router-link :to="'/oddil/' + item.club_shortcut">{{ item.club_name }}</router-link>
    </template>
    <template #[`item.members`]="{ item }">
      {{ item.members }}
    </template>   
    <template #[`item.club_shortcut`]="{ item }">
      {{ item.club_shortcut }}
    </template>   
    <template v-slot:bottom></template> 
    </v-data-table>
    </v-col>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import apiClient from '/api.js';

const clubs = ref([]);
const clubHeaders = ref([
  { title: 'Název oddílu', align: 'start', key: 'club_name' },
  { title: 'Členů', align: 'start', key: 'members', width: '100px' },
  { title: 'Zkratka', key: 'club_shortcut', width: '100px' },
]);

// Načtení dat oddílů z API při vytvoření komponenty
onMounted(async () => {
  try {
    const response = await apiClient.get('club/');
    clubs.value = response.data.filter(club => club.club_type === 'club');
  } catch (error) {
    console.error('Chyba při načítání oddílů:', error);
  }
});
</script>

<style scoped>
</style>
